import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { Staff } from "./Staff";
import { StaffMember } from "./StaffMember";

export const StaffArea: FC = () => {
    return (
        <Routes>
            <Route index element={<Staff />} />
            <Route path=":staffId/*" element={<StaffMember />} />
        </Routes>
    );
};