import { FC, PropsWithChildren } from "react";
import { AdminPermission } from "AdminApi/AdminApi";
import { hasAnyPermission } from "Helpers/Permissions";
import { useApp } from "Context/AppContext";

interface HasAnyPermissionProps {
    permissions: AdminPermission[];
}

export const HasAnyPermission: FC<PropsWithChildren<HasAnyPermissionProps>> = (props) => {
    const {session } = useApp();

    if (hasAnyPermission(session, props.permissions)) return props.children;

    return null;
};