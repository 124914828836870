// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RHURVxmbWbf0MTcXR7zR{display:flex;align-items:baseline;gap:6px}`, "",{"version":3,"sources":["webpack://./Client/Components/ResourceEditor/ResourceEditor.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,oBAAA,CACA,OAAA","sourcesContent":[".resourceEditor{display:flex;align-items:baseline;gap:6px}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resourceEditor": `RHURVxmbWbf0MTcXR7zR`
};
export default ___CSS_LOADER_EXPORT___;
