// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,500;0,600;0,700;1,200;1,400;1,500;1,600;1,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StiZH3D6gqFjgG7N5eVw{background-color:#fee;padding:8px;border-radius:8px}`, "",{"version":3,"sources":["webpack://./Client/Pages/Login.module.scss","webpack://./../Aphrodite.React/Styles/defaults.scss"],"names":[],"mappings":"AAEA,sBACE,qBCWW,CDVX,WAAA,CACA,iBAAA","sourcesContent":["@import\"https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,500;0,600;0,700;1,200;1,400;1,500;1,600;1,700&display=swap\";.panel{background-color:#fee;padding:8px;border-radius:8px}",null],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel": `StiZH3D6gqFjgG7N5eVw`
};
export default ___CSS_LOADER_EXPORT___;
