import { AutoTable, Card, Header, Input, RadioCheck, Select, TextArea } from "aphrodite.react/Components";
import { FC, useEffect, useState } from "react";
import { useApp } from "Context/AppContext";
import { useValidator } from "aphrodite.react/Hooks";
import { useAdmin } from "../AdminContext";
import { UniversitiesClient, UniversityGetAllResponse, WorkflowStepsClient, WorkflowStepsGetAllResponse } from "AdminApi/AdminApi";
import { useEventTemplates } from "./EventTemplatesContext";
import { HttpStatusCode } from "aphrodite.react/Helpers";
import styles from "./EventTemplateCreate.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { WorkflowStepPropertyEditor } from "Components/WorkflowStepPropertyEditor/WorkflowStepPropertyEditor";
import { WorkflowStepRunAt } from "Components/WorkflowStepRunAt/WorkflowStepRunAt";
import { WorkflowStepSkipAfter } from "Components/WorkflowStepSkipAfter/WorkflowStepSkipAfter";
import { TaskConfiguration } from "../../../Components/TaskConfiguration/TaskConfiguration";

export const EventTemplateCreate: FC = () => {
    const { resource } = useApp();
    const { countries } = useAdmin();
    const { workflows, orientations, genders } = useEventTemplates();
    const validator = useValidator();

    const [workflowSteps, setWorkflowSteps] = useState<WorkflowStepsGetAllResponse[] | null>(null);
    const [universities, setUniversities] = useState<UniversityGetAllResponse[] | null>(null);

    const [workflowId, setWorkflowId] = useState<string>("");
    const [internalName, setInternalName] = useState<string>("");
    const [displayName, setDisplayName] = useState<string>("");
    const [shortDescription, setShortDescription] = useState<string>("");
    const [longDescription, setLongDescription] = useState<string>("");

    const [countryId, setCountryId] = useState<string>("");
    const [universityTargeting, setUniversityTargeting] = useState<string>("0");
    const [universityIds, setUniversityIds] = useState<string[]>([]);
    const [numGroups, setNumGroups] = useState<string>("2");

    const [orientationsA, setOrientationsA] = useState<string[]>([]);
    const [gendersA, setGendersA] = useState<string[]>([]);
    const [minSizeA, setMinSizeA] = useState<string>("20");
    const [maxSizeA, setMaxSizeA] = useState<string>("20");

    const [orientationsB, setOrientationsB] = useState<string[]>([]);
    const [gendersB, setGendersB] = useState<string[]>([]);
    const [minSizeB, setMinSizeB] = useState<string>("20");
    const [maxSizeB, setMaxSizeB] = useState<string>("20");

    const getWorkflow = async () => {
        if (!!workflowId) {
            const response = await new WorkflowStepsClient().getAll(parseInt(workflowId));

            if (response.status === HttpStatusCode.Http200Ok) {
                setWorkflowSteps(response.result);
            }
        }
    };

    const getUniversities = async () => {
        const response = await new UniversitiesClient().getAll();

        if (response.status === HttpStatusCode.Http200Ok) {
            setUniversities(response.result);
        }
    };

    useEffect(() => {
        setWorkflowSteps(null);
        if (!!workflowId) {
            getWorkflow();
        }
    }, [workflowId]);

    useEffect(() => {
        if (!universities && universityTargeting === "1") {
            getUniversities();
        }
    }, [universityTargeting]);

    return (
        <>
            <Header
                title={resource("EventTemplateCreate")}
            />

            <Select
                id="eventType"
                label={resource("EventType")}
                items={[{name: "Classic", id: "1"}]}
                descriptions={w => w.name}
                values={w => w.id}
                validator={validator}
                validations={{ required: true }}
            />

            <Input
                id="internalName"
                label={resource("EventTemplateInternalName")}
                validator={validator}
                validations={{ required: true }}
                value={internalName}
                onChange={e => setInternalName(e.target.value)}
            />

            <Input
                id="displayName"
                label={resource("EventTemplateDisplayName")}
                validator={validator}
                validations={{ required: true }}
                value={displayName}
                onChange={e => setDisplayName(e.target.value)}
            />

            <Input
                id="shortDescription"
                label={resource("EventTemplateShortDescription")}
                validator={validator}
                validations={{ required: true }}
                value={shortDescription}
                onChange={e => setShortDescription(e.target.value)}
            />

            <TextArea
                id="longDescription"
                label={resource("EventTemplateLongDescription")}
                validator={validator}
                validations={{ required: true }}
                value={longDescription}
                onChange={e => setLongDescription(e.target.value)}
                grow={true}
            />

            <Input
                id="smallImage"
                label={resource("EventTemplateSmallImage")}
                type="file"
                multiple={false}
            />

            <Input
                id="largeImage"
                label={resource("EventTemplateLargeImage")}
                type="file"
                multiple={false}
            />

            <Header
                level={2}
                title={resource("EventTemplateTargeting")}
            />

            <Select
                id="countryId"
                label={resource("Country")}
                items={countries!}
                values={c => c.id.toString()}
                descriptions={c => c.name}
                value={countryId}
                onChange={(e) => setCountryId(e.target.value)}
                validator={validator}
                validations={{ required: false }}
                noneOption={resource("NoneValue")}
            />

            <Select
                id="universityTargeting"
                label={resource("Universities")}
                onChange={e => setUniversityTargeting(e.target.value)}
                value={universityTargeting}
                items={[
                    { value: 0, name: resource("None") },
                    { value: 1, name: resource("Some") },
                    { value: 2, name: resource("All") },
                ]}
                descriptions={i => i.name}
                values={i => i.value.toString()}
            />

            {universityTargeting === "1" && (
                <>
                    {!universities && (
                        <>{resource("EventTemplateLoadingUniversities")}</>
                    )}
                    {!!universities && (
                        <RadioCheck
                            multiple={true}
                            id="universities"
                            items={universities}
                            values={u => u.id.toString()}
                            descriptions={u => u.name}
                            validator={validator}
                            validations={{ required: true, minLength: 1 }}
                            value={universityIds}
                            onChange={setUniversityIds}
                        />
                    )}
                </>
            )}

            <Select
                id="numGroups"
                label={resource("EventTemplateNumberOfGroups")}
                items={["1", "2"]}
                values={c => c}
                descriptions={c => c}
                validator={validator}
                validations={{ required: true }}
                onChange={e => setNumGroups(e.target.value)}
                value={numGroups}
            />

            <div className={styles.split}>
                <Card>
                    <Header level={3} title={resource("GroupA")} />

                    <RadioCheck
                        multiple={true}
                        id="orientationsA"
                        label={resource("Orientations")}
                        items={orientations!}
                        values={o => o.id.toString()}
                        descriptions={o => o.name}
                        validator={validator}
                        validations={{ required: true }}
                        value={orientationsA}
                        onChange={setOrientationsA}
                    />

                    <RadioCheck
                        multiple={true}
                        id="gendersA"
                        label={resource("Genders")}
                        items={genders!}
                        values={o => o.id.toString()}
                        descriptions={o => o.name}
                        validator={validator}
                        validations={{ required: true }}
                        value={gendersA}
                        onChange={setGendersA}
                    />

                    <Input
                        id="minSizeA"
                        type="number"
                        label={resource("MinimumSize")}
                        validator={validator}
                        validations={{ required: true, minValue: 1 }}
                        value={minSizeA}
                        onChange={e => setMinSizeA(e.target.value)}
                    />

                    <Input
                        id="targetSizeA"
                        type="number"
                        label={resource("TargetSize")}
                        validator={validator}
                        validations={{ required: true, minValue: 1 }}
                    />

                    <Input
                        id="maxSizeA"
                        type="number"
                        label={resource("MaximumSize")}
                        validator={validator}
                        validations={{ required: true, minValue: 1 }}
                        value={maxSizeA}
                        onChange={e => setMaxSizeA(e.target.value)}
                    />
                </Card>
                <Card>
                    <Header level={3} title={resource("GroupB")} />

                    {numGroups === "1" && (
                        <div>{resource("EventTemplateGroupBDisabled")}</div>
                    )}

                    {numGroups === "2" && (
                        <>
                            <RadioCheck
                                multiple={true}
                                id="orientationsB"
                                label={resource("Orientations")}
                                items={orientations!}
                                values={o => o.id.toString()}
                                descriptions={o => o.name}
                                validator={validator}
                                validations={{ required: true }}
                                value={orientationsB}
                                onChange={setOrientationsB}
                            />

                            <RadioCheck
                                multiple={true}
                                id="gendersB"
                                label={resource("Genders")}
                                items={genders!}
                                values={o => o.id.toString()}
                                descriptions={o => o.name}
                                validator={validator}
                                validations={{ required: true }}
                                value={gendersB}
                                onChange={setGendersB}
                            />

                            <Input
                                id="minSizeB"
                                type="number"
                                label={resource("MinimumSize")}
                                validator={validator}
                                validations={{ required: true, minValue: 1 }}
                                value={minSizeB}
                                onChange={e => setMinSizeB(e.target.value)}
                            />

                            <Input
                                id="targetSizeB"
                                type="number"
                                label={resource("TargetSize")}
                                validator={validator}
                                validations={{ required: true, minValue: 1 }}
                            />

                            <Input
                                id="maxSizeB"
                                type="number"
                                label={resource("MaximumSize")}
                                validator={validator}
                                validations={{ required: true, minValue: 1 }}
                                value={maxSizeB}
                                onChange={e => setMaxSizeB(e.target.value)}
                            />
                        </>
                    )}
                </Card>
            </div>

            <Header
                level={2}
                title={resource("EventTemplateConfigureSchedule")}
            />

            <Header
                level={3}
                title="Event Marketing"
            />

            <TaskConfiguration
                name="Publish Event"
                notifications={[{ id: 1, target: "Send notification to targeted customers" }]}
            />

            <TaskConfiguration
                name="Open Sign-Up"
                notifications={[{ id: 1, target: "Send notification to targeted customers" }]}
            />

            <TaskConfiguration
                name="Send Reminder Notification"
                notifications={[{ id: 1, target: "Send notification to targeted customers" }]}
            />

            <TaskConfiguration
                name="Close Sign-Up and Generate Groups"
            />

            <Header
                level={3}
                title="Present Profiles"
            />

            <TaskConfiguration
                name="Notify for Event Start"
                isBasis={true}
                notifications={[{ id: 1, target: "Send notification to signed-up customers" }]}
            />

            <TaskConfiguration
                name="Show Names"
                notifications={[{ id: 1, target: "Send notification to signed-up customers" }]}
            />

            <TaskConfiguration
                name="Show Text"
                notifications={[{ id: 1, target: "Send notification to signed-up customers" }]}
            />

            <TaskConfiguration
                name="Show Audio"
                notifications={[{ id: 1, target: "Send notification to signed-up customers" }]}
            />

            <TaskConfiguration
                name="Show Images"
                notifications={[{ id: 1, target: "Send notification to signed-up customers" }]}
            />

            <TaskConfiguration
                name="Show Videos"
                notifications={[{ id: 1, target: "Send notification to signed-up customers" }]}
            />

            <Header
                level={3}
                title="Profile Ranking"
            />

            <TaskConfiguration
                name="Open Profile Ranking"
                notifications={[{ id: 1, target: "Send notification to signed-up customers" }]}
            />

            <Header
                level={3}
                title="Event Ending"
            />

            <TaskConfiguration
                name="Notify Ending Soon"
                notifications={[{ id: 1, target: "Send notification to signed-up customers" }]}
            />

            <TaskConfiguration
                name="End Event and Matching"
                notifications={[
                    { id: 1, target: "Send notification to matched customers" },
                    { id: 2, target: "Send notification to unmatched customers" }
                ]}
            />


            {/*
            {!workflowId &&
                <div>{resource("EventTemplateNoWorkflow")}</div>
            }

            {!!workflowId && !workflowSteps && (
                <div>{resource("EventTemplateLoadingWorkflow")}</div>
            )}

            {!!workflowId && !!workflowSteps && !workflowSteps.length && (
                <div>{resource("EventTemplateWorkflowNoConfiguration")}</div>
            )}

            {!!workflowSteps?.length && (
                <AutoTable
                    items={workflowSteps}
                    rowKey={ws => ws.id}
                    columns={[
                        {
                            header: resource("Step"), display: ws => (
                                <span>{ws.stepTypeId} {ws.isScheduleBasis && <FontAwesomeIcon icon={faClock} />}</span>
                            )
                        },
                        {
                            header: resource("EventTemplateStepRunAt"),
                            display: ws => <WorkflowStepRunAt step={ws} />
                        },
                        {
                            header: resource("EventTemplateStepSkipAfter"),
                            display: ws => <WorkflowStepSkipAfter step={ws} />
                        },
                        {
                            header: resource("Properties"), display: ws => ws.properties?.map(p => (
                                <WorkflowStepPropertyEditor stepId={ws.id} property={p} />
                            ))
                        }
                    ]}
                />
            )}
            */}
        </>
    );
};