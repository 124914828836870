// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oRWATjhDE98FbH3s2mwt{display:flex;flex-direction:row;gap:8px}.oRWATjhDE98FbH3s2mwt div{flex:1 1 50%}`, "",{"version":3,"sources":["webpack://./Client/Pages/Admin/EventTemplates/EventTemplateCreate.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA,CACA,OAAA,CAEA,0BACI,YAAA","sourcesContent":[".split{display:flex;flex-direction:row;gap:8px}.split div{flex:1 1 50%}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"split": `oRWATjhDE98FbH3s2mwt`
};
export default ___CSS_LOADER_EXPORT___;
