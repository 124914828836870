import * as React from "react";
import { InputCommonProps, ValidatedInputProps } from "./Common";
import { useForceUpdate } from "../../Hooks";
import commonStyles from "./Common.module.scss";
import { classNames } from "../../Utilities";
import textAreaStyles from "./TextArea.module.scss";

interface TextAreaProps {
    grow?: boolean;
    autoFocus?: boolean;
}

export const TextArea: React.FC<TextAreaProps & ValidatedInputProps & InputCommonProps & React.TextareaHTMLAttributes<HTMLTextAreaElement>> = (props) => {
    const {
        validator,
        validations,
        customMessages,
        label,
        className,
        grow,
        autoFocus,
        ...inputProps
    } = props;

    const forceUpdate = useForceUpdate();
    const textAreaRef = React.useRef<HTMLTextAreaElement>(null);

    React.useEffect(() => {
        validator?.register({
            key: props.id,
            validations: validations,
            value: props.value?.toString(),
            forceUpdate: forceUpdate,
            validationMessages: customMessages
        });

        if (autoFocus) textAreaRef?.current?.focus();

        return () => {
            validator?.unregister(props.id);
        };
    }, []);

    React.useEffect(() => {
        if (!!validator) {
            validator.setValue(props.id, props.value?.toString() ?? null);
            forceUpdate();
        }
    }, [props.value]);

    const isErrorState = (): boolean => {
        return !!validator && validator.submitAttempted() && validator.getErrors(props.id).length > 0;
    };

    return (
        <div className={commonStyles.container}>
            {!!label && (
                <div className={classNames([commonStyles.label, isErrorState() && commonStyles.error])}>
                    <label data-required={props.validations?.required} htmlFor={props.id}>{label}</label>
                </div>
            )}

            <div className={commonStyles.input}>
                {!!grow && (
                    <div className={textAreaStyles.growWrap} data-replicated-value={props.value}>

                        <textarea
                            ref={textAreaRef}
                            className={classNames([textAreaStyles.textArea, className, isErrorState() && commonStyles.error])}
                            {...inputProps}
                        />
                    </div>
                )}
                {!grow && (
                    <textarea
                        className={classNames([textAreaStyles.textArea, className, isErrorState() && commonStyles.error])}
                        {...inputProps}
                    />
                )}
            </div>

            {isErrorState() && (
                <div className={commonStyles.validation}>
                    {validator?.getErrors(props.id).map((message) => {
                        return <div key={message}>{message}</div>;
                    })}
                </div>
            )}

        </div>
    );
};