import { IconDefinition, IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import styles from "./Tile.module.scss";

interface TileProps {
    title: string;
    icon: IconDefinition;
    onClick?: () => void;
}

export const Tile: FC<TileProps> = (props) => {
    return (
        <div className={styles.tile} onClick={props.onClick}>
            <FontAwesomeIcon icon={props.icon} size="4x" />
            <div className={styles.title}>{props.title}</div>
        </div>
    );
}