import * as React from "react";
import { InputCommonProps, ValidatedInputProps } from "./Common";
import commonStyles from "./Common.module.scss";
import { classNames } from "../../Utilities";
import { useForceUpdate } from "../../Hooks";

interface RadioCheckProps<T> {

    multiple?: boolean;
    items: T[];
    descriptions: (item: T) => string;
    values: (item: T) => string;
    value?: string[];
    onChange?: (value: string[]) => void;
}

export const RadioCheck = <T,>(props: RadioCheckProps<T> & ValidatedInputProps & InputCommonProps) => {
    const {
        validator,
        validations,
        customMessages,
        label
    } = props;

    const forceUpdate = useForceUpdate();

    const handleItemClicked = (e: React.ChangeEvent<HTMLInputElement>, item: T) => {
        if (e.target.checked) {
            if (props.multiple) {
                props.onChange?.([...props.value ?? [], props.values(item)]);
            } else {
                props.onChange?.([props.values(item)]);
            }
        } else {
            props.onChange?.(props.value?.filter((v) => v !== props.values(item)) ?? []);
        }
    };

    React.useEffect(() => {
        validator?.register({
            key: props.id,
            validations: validations,
            value: props.value,
            forceUpdate: forceUpdate,
            validationMessages: customMessages
        });

        return () => {
            validator?.unregister(props.id);
        };
    }, []);

    React.useEffect(() => {
        if (!!validator) {
            validator.setValue(props.id, props.value ?? null);
            forceUpdate();
        }
    }, [props.value]);

    const isErrorState = (): boolean => {
        return !!props.validator && props.validator.submitAttempted() && props.validator.getErrors(props.id).length > 0;
    };

    return (
        <div className={commonStyles.container}>
            {!!props.label && (
                <div className={classNames([commonStyles.label, isErrorState() && commonStyles.error])}>
                    <label htmlFor={props.id}>{label}</label>
                </div>
            )}

            {props.items.map((item, index) => (
                <div key={props.values(item)}>
                    <input
                        id={`${props.id}-${index}`}
                        type={props.multiple ? "checkbox" : "radio"}
                        checked={props.value?.includes(props.values(item)) ?? false}
                        onChange={(e) => handleItemClicked(e, item)}
                    />
                    <label htmlFor={`${props.id}-${index}`}>{props.descriptions(item)}</label>
                </div>
            ))}

            {isErrorState() && (
                <div className={commonStyles.validation}>
                    {props.validator?.getErrors(props.id).map((message) => {
                        return <div key={message}>{message}</div>;
                    })}
                </div>
            )}

        </div>
    );
};