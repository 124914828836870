// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KAdZMERnYrvwMDdjvNCA{position:fixed;top:0;left:0;right:0;height:48px;background-color:#000;color:#fff;padding:6px 8px;font-size:20px;display:flex;justify-content:space-between}.KAdZMERnYrvwMDdjvNCA .FDlVnueQHDKxA16Np84K{font-weight:bold;height:100%;display:flex;align-items:center}.KAdZMERnYrvwMDdjvNCA .FDlVnueQHDKxA16Np84K .JaXbIuD0soLHGmEvT1rP{cursor:pointer}.KAdZMERnYrvwMDdjvNCA .AYJwjUJMC4n0uyOgiE59{height:100%;display:flex;align-items:center;gap:6px}.WOLsCgjbVFtkwqIOy8zQ{position:fixed;top:48px;left:0;bottom:0;right:0;overflow-y:auto;padding:8px}`, "",{"version":3,"sources":["webpack://./Client/Layout.module.scss"],"names":[],"mappings":"AAAA,sBACI,cAAA,CACA,KAAA,CACA,MAAA,CACA,OAAA,CACA,WAAA,CACA,qBAAA,CACA,UAAA,CACA,eAAA,CACA,cAAA,CACA,YAAA,CACA,6BAAA,CAEA,4CACI,gBAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CAEA,kEACI,cAAA,CAIR,4CACI,WAAA,CACA,YAAA,CACA,kBAAA,CACA,OAAA,CAIR,sBACI,cAAA,CACA,QAAA,CACA,MAAA,CACA,QAAA,CACA,OAAA,CACA,eAAA,CACA,WAAA","sourcesContent":[".topBar{position:fixed;top:0;left:0;right:0;height:48px;background-color:#000;color:#fff;padding:6px 8px;font-size:20px;display:flex;justify-content:space-between}.topBar .left{font-weight:bold;height:100%;display:flex;align-items:center}.topBar .left .text{cursor:pointer}.topBar .right{height:100%;display:flex;align-items:center;gap:6px}.content{position:fixed;top:48px;left:0;bottom:0;right:0;overflow-y:auto;padding:8px}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topBar": `KAdZMERnYrvwMDdjvNCA`,
	"left": `FDlVnueQHDKxA16Np84K`,
	"text": `JaXbIuD0soLHGmEvT1rP`,
	"right": `AYJwjUJMC4n0uyOgiE59`,
	"content": `WOLsCgjbVFtkwqIOy8zQ`
};
export default ___CSS_LOADER_EXPORT___;
