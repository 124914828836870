export abstract class DateFormatter {
    /**
     * Parses a date and time and returns the date as a string in ISO format with no change to the timezone
     * @param input The date to parse and format
     * @returns A string in the format "yyyy-MM-dd"
     */
    static isoDateLiteral(input: string): string {
        return new Date(this.ensureUtc(input)).toISOString().split("T")[0];
    }

    /**
     * Parses a date and time and returns the time as a string in ISO format with no change to the timezone
     * @param input The date for parse and format
     * @returns A string in the format "hh:mm:ss"
     */
    static isoTimeLiteral(input: string): string {
        return new Date(this.ensureUtc(input)).toISOString().split("T")[1].slice(0, 8);
    }

    /**
     * Parses a date and time and returns the date and time as a string in ISO format with no change to the timezone
     * @param input The date for parse and format
     * @returns A string in the format "hh:mm:ss"
     */
    static isoDateTimeLiteral(input: string): string {
        return new Date(this.ensureUtc(input)).toISOString();
    }

    /**
     * Parses a date and time and returns the date as a string in ISO format in the local timezone
     * @param input The date to parse and format
     * @returns A string adjusted to the current timezone in the format "yyyy-MM-dd"
     */
    static isoDateLocal(input: string | number | Date): string {
        return new Date(input).toLocaleDateString("sv-SE");
    }

    /**
     * Parses a date and time and returns the time as a string in ISO format in the local timezone
     * @param input The date to parse and format
     * @returns A string adjusted to the current timezone in the format "hh:mm:ss"
     */
    static isoTimeLocal(input: string | number | Date): string {
        return new Date(input).toLocaleTimeString("sv-SE");
    }

    /**
     * Parses a date and returns an ISO format DateTime in UTC
     * @param date The date to parse and format
     */
    static isoDateTimeUtc(date: string): string;
    /**
     * Parses a date and time and returns an ISO format DateTime in UTC
     * @param date The date to parse and format
     * @param time The time to parse and format
     */
    static isoDateTimeUtc(date: string, time: string): string;
    static isoDateTimeUtc(date: string, time?: string): string {
        let input = !!time ? this.isoDateLiteral(date) : this.isoDateTimeLiteral(date);
        if (!!time) {
            input += `T${time}`;
        }

        return new Date(input).toISOString();
    }

    /**
     * Parses a date and time and returns the date as a string in the locale format and local timezone
     * @param input The date to parse and format
     * @returns A date string adjusted to the current timezone in the locale format
     */
    static localeDateLocal(input: string | number | Date): string {
        return new Date(input).toLocaleDateString();
    }

    /**
     * Parses a date and time and returns the time as a string in the locale format and local timezone
     * @param input The date and time to parse and format
     * @returns A time string adjusted to the current timezone in the locale format
     */
    static localeTimeLocal(input: string | number | Date): string {
        return new Date(input).toLocaleTimeString();
    }

    /**
     * Parses a date and time and returns the date as a string in the locale format with no change to the timezone
     * @param input The date to parse and format
     * @returns A date string in the locale format with no change to the timezone
     */
    static localeDateLiteral(input: string): string {
        return new Date(this.ensureLocal(input)).toLocaleDateString();
    }

    /**
     * Parses a date and time and returns the time as a string in the locale format with no change to the timezone
     * @param input The date and time to parse and format
     * @returns A time string in the locale format with no change to the timezone
     */
    static localeTimeLiteral(input: string): string {
        return new Date(this.ensureLocal(input)).toLocaleTimeString();
    }

    private static ensureUtc(input: string): string {
        return input.endsWith("Z") ? input : `${input}Z`;
    }

    private static ensureLocal(input: string): string {
        return !input.endsWith("Z") ? input : input.slice(0, -1);
    }
}