import * as React from "react";
import { InputCommonProps, ValidatedInputProps } from "./Common";
import { useForceUpdate } from "../../Hooks";
import commonStyles from "./Common.module.scss";
import { classNames } from "../../Utilities";

interface SelectProps<T> {
    items: T[];
    descriptions: (item: T) => string;
    values: (item: T) => string;
    noneOption?: string;
    autoFocus?: boolean;
}

export const Select = <T,>(props: SelectProps<T> & ValidatedInputProps & InputCommonProps & React.SelectHTMLAttributes<HTMLSelectElement>) => {
    const {
        validator,
        validations,
        customMessages,
        label,
        className,
        noneOption,
        values,
        descriptions,
        items,
        autoFocus,
        ...inputProps
    } = props;

    const forceUpdate = useForceUpdate();
    const selectRef = React.useRef<HTMLSelectElement>(null);

    React.useEffect(() => {
        validator?.register({
            key: props.id,
            validations: validations,
            value: props.value?.toString(),
            forceUpdate: forceUpdate,
            validationMessages: customMessages
        });

        if (autoFocus) selectRef?.current?.focus();

        return () => {
            validator?.unregister(props.id);
        };
    }, []);

    React.useEffect(() => {
        if (!!validator) {
            validator.setValue(props.id, props.value?.toString() ?? null);
            forceUpdate();
        }
    }, [props.value]);

    const isErrorState = (): boolean => {
        return !!validator && validator.submitAttempted() && validator.getErrors(props.id).length > 0;
    };

    return (
        <div className={commonStyles.container}>
            {!!label && (
                <div className={classNames([commonStyles.label, isErrorState() && commonStyles.error])}>
                    <label data-required={props.validations?.required} htmlFor={props.id}>{label}</label>
                </div>
            )}

            <div className={commonStyles.input}>
                <select
                    ref={selectRef}
                    className={classNames([className, isErrorState() && commonStyles.error])}
                    {...inputProps}
                >
                    {(!validations?.required || props.value === "") && (
                        <option value="">{!validations?.required ? noneOption : ""}</option>
                    )}

                    {props.items.map((item, index) => (
                        <option
                            id={`${props.id}-${index}`}
                            key={props.values(item)}
                            value={props.values(item)}
                        >
                            {props.descriptions(item)}
                        </option>
                    ))}
                </select>
            </div>

            {isErrorState() && (
                <div className={commonStyles.validation}>
                    {validator?.getErrors(props.id).map((message) => {
                        return <div key={message}>{message}</div>;
                    })}
                </div>
            )}

        </div>
    );
};