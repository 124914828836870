import { FC, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { HttpStatusCode } from "aphrodite.react/Helpers";
import { CountryClient, CountryGetAllResponse, SessionClient } from "AdminApi/AdminApi";
import { AdminContext } from "./AdminContext";
import { Admin } from "./Admin";
import { LanguagesArea } from "./Languages/LanguagesArea";
import { CustomersArea } from "./Customers/CustomersArea";
import { StaffArea } from "./Staff/StaffArea";
import { EventsArea } from "./Events/EventsArea";
import { WorkflowsArea } from "./Workflows/WorkflowsArea";
import { useApp } from "Context/AppContext";
import { VersionHistory } from "./VersionHistory/VersionHistory";
import { EventTemplatesArea } from "./EventTemplates/EventTemplatesArea";
import { EventImages } from "./EventImages/EventImages";

export const AdminArea: FC = () => {
    const { session, setSession } = useApp();

    const [countries, setCountries] = useState<CountryGetAllResponse[] | null>(null);

    const refreshSession = async () => {
        const response = await new SessionClient().get();

        if (response.status === HttpStatusCode.Http200Ok) {
            setSession(response.result);
        }
    };

    const refreshCountries = async () => {
        const response = await new CountryClient().getAll();

        if (response.status === HttpStatusCode.Http200Ok) {
            setCountries(response.result);
        }
    };

    useEffect(() => {
        refreshSession();
    }, []);

    useEffect(() => {
        if (!!session) {
            refreshCountries();
        }
    }, [session]);

    if (!countries) return null;

    return (
        <AdminContext.Provider value={{ countries, refreshCountries }}>
            <Routes>
                <Route index element={<Admin />} />
                <Route path="customers/*" element={<CustomersArea />} />
                <Route path="events/*" element={<EventsArea />} />
                <Route path="eventimages/*" element={<EventImages />} />
                <Route path="eventtemplates/*" element={<EventTemplatesArea />} />
                <Route path="languages/*" element={<LanguagesArea />} />
                <Route path="staff/*" element={<StaffArea />} />
                <Route path="version/*" element={<VersionHistory />} />
                <Route path="workflows/*" element={<WorkflowsArea />} />
            </Routes>
        </AdminContext.Provider>
    );
};