import { GenderGetAllResponse, OrientationGetAllResponse, WorkflowGetAllResponse } from "AdminApi/AdminApi";
import { createContext, useContext } from "react";

interface EventTemplatesContextState {
    workflows: WorkflowGetAllResponse[] | null;
    genders: GenderGetAllResponse[] | null;
    orientations: OrientationGetAllResponse[] | null;
}

export const EventTemplatesContext = createContext<EventTemplatesContextState>({
    workflows: null,
    genders: null,
    orientations: null
});

export function useEventTemplates() {
    return useContext(EventTemplatesContext);
}