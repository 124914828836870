// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,500;0,600;0,700;1,200;1,400;1,500;1,600;1,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{box-sizing:border-box}body{font-family:"Poppins","Segoe UI",Tahoma,Geneva,Verdana,sans-serif}table{width:100%;border-collapse:collapse}table th,table td{padding:2px 4px}table thead{background-color:#ca95f7;color:#000}table thead tr th{text-align:left}table tbody tr td{border-bottom:1px solid #deb1d9}body{position:absolute;top:0;left:0;right:0;bottom:0;overflow-y:auto;margin:0;padding:0;background-color:#fee}`, "",{"version":3,"sources":["webpack://./../Aphrodite.React/Styles/common.scss","webpack://./../Aphrodite.React/Styles/defaults.scss","webpack://./Client/Styles/Global.scss"],"names":[],"mappings":"AAEA,EACI,qBAAA,CAGJ,KACI,iECJU,CDOd,MACI,UAAA,CACA,wBAAA,CAEA,kBACE,eAAA,CAGF,YACI,wBCGE,CDFF,UAAA,CAGE,kBACE,eAAA,CAOA,kBACI,+BAAA,CE7BhB,KACI,iBAAA,CACA,KAAA,CACA,MAAA,CACA,OAAA,CACA,QAAA,CACA,eAAA,CACA,QAAA,CACA,SAAA,CACA,qBDES","sourcesContent":[null,null,"@import\"https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,500;0,600;0,700;1,200;1,400;1,500;1,600;1,700&display=swap\";*{box-sizing:border-box}body{font-family:\"Poppins\",\"Segoe UI\",Tahoma,Geneva,Verdana,sans-serif}table{width:100%;border-collapse:collapse}table th,table td{padding:2px 4px}table thead{background-color:#ca95f7;color:#000}table thead tr th{text-align:left}table tbody tr td{border-bottom:1px solid #deb1d9}body{position:absolute;top:0;left:0;right:0;bottom:0;overflow-y:auto;margin:0;padding:0;background-color:#fee}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
