import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AdminPermission, ResourceValueClient, ResourceValueGetResponse, ResourceValuePutRequest } from "AdminApi/AdminApi";
import { HttpStatusCode } from "aphrodite.react/Helpers";
import { AutoTable } from "aphrodite.react/Components";
import { useApp } from "Context/AppContext";
import { ResourceEditor } from "Components/ResourceEditor/ResourceEditor";
import { HasPermission } from "Components/HasPermission";

type ResourcesParams = {
    languageId: string;
};

export const Resources: FC = () => {
    const { resource } = useApp();
    const params = useParams<ResourcesParams>();
    const client = new ResourceValueClient();

    const [resources, setResources] = useState<ResourceValueGetResponse[] | null>(null);

    const refreshResources = async () => {
        const response = await client.getAll(parseInt(params.languageId!));

        if (response.status === HttpStatusCode.Http200Ok) {
            setResources(response.result);
        }
    };

    const handleResourceUpdate = async (id: string, request: ResourceValuePutRequest) => {
        const response = await client.update(parseInt(params.languageId!), id, request);

        if (response.status === HttpStatusCode.Http200Ok) {
            refreshResources();
        }
    };

    useEffect(() => {
        refreshResources();
    }, []);

    return (
        <>
            <AutoTable
                items={resources}
                rowKey={r => r.id}
                columns={[
                    { header: resource("ResourceIdentifier"), display: r => r.id },
                    {
                        header: resource("Value"), display: r => (
                            <HasPermission permission={AdminPermission.ResourceUpdate}>
                                <ResourceEditor
                                    resource={r}
                                    onUpdate={handleResourceUpdate}
                                />
                            </HasPermission>
                        )
                    },
                    { header: resource("Description"), display: r => r.description }
                ]}
            />
        </>
    );
};