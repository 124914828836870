// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../Aphrodite.Admin.Web/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../Aphrodite.Admin.Web/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RkkoYLXq3vkD9wB2yiUj{text-align:center}`, "",{"version":3,"sources":["webpack://./../Aphrodite.React/Components/AutoTable/AutoTable.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA","sourcesContent":[".noData{text-align:center}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noData": `RkkoYLXq3vkD9wB2yiUj`
};
export default ___CSS_LOADER_EXPORT___;
