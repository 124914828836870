import { createRoot } from "react-dom/client";
import { App } from "./App";
import "./Styles/Global.scss";
import { Configuration } from "./Context/Configuration";
import { BrowserRouter } from "react-router-dom";

Configuration.refresh()
    .then(() => {
        const container = document.getElementById("root")!;
        const root = createRoot(container);
        root.render(
            <BrowserRouter>
                <App />
            </BrowserRouter>
        );
    });