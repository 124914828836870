import * as React from "react";
import { classNames } from "../../Utilities";
import styles from "./CenterCover.module.scss";

interface CenterCoverProps {
  coverType?: CoverType;
  backgroundClassName?: string;
}

export enum CoverType {
  FullScreen,
  Relative
}

export const CenterCover: React.FC<React.PropsWithChildren<CenterCoverProps>> = (props) => {
  const getCoverClass = (): string => {
    switch (props.coverType) {
      case CoverType.FullScreen:
        return styles.fullScreen;
      case CoverType.Relative:
        return styles.relative;
      default:
        return styles.fullScreen;
    }
  };

  return (
      <div className={classNames([styles.cover, getCoverClass(), props.backgroundClassName])}>
      {props.children}
    </div>
  );
};