import { Cookies, HttpStatusCode } from "aphrodite.react/Helpers";
import { DefaultLanguageClient } from "AdminApi/AdminApi";

export class Language {
    static async get(): Promise<number> {
        const override = Cookies.get("language");
        if (!!override) return parseInt(override);

        const client = new DefaultLanguageClient();
        var response = await client.post({
            languages: [...navigator.languages]
        });

        if (response.status === HttpStatusCode.Http200Ok) {
            Language.set(response.result.languageId);
            return response.result.languageId;
        }
        else {
            return 1;
        }
    }

    static set(language: number): void {
        Cookies.set("language", language.toString(), 365);
    }
}