import { FC, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Events } from "./Events";
import { EventsContext } from "./EventsContext";
import { WorkflowClient, WorkflowGetAllResponse } from "AdminApi/AdminApi";
import { HttpStatusCode } from "aphrodite.react/Helpers";
import { Event } from "./Event";
import { EventCreate } from "./EventCreate";

export const EventsArea: FC = () => {
    const [workflows, setWorkflows] = useState<WorkflowGetAllResponse[] | null>(null);

    const refreshWorkflows = async () => {
        const response = await new WorkflowClient().getAll();

        if (response.status === HttpStatusCode.Http200Ok) {
            setWorkflows(response.result);
        }
    };

    useEffect(() => {
        refreshWorkflows();
    }, []);

    if (!workflows) return null;

    return (
        <EventsContext.Provider value={{ workflows }}>
            <Routes>
                <Route index element={<Events />} />
                <Route path="new" element={<EventCreate />} />
                <Route path=":eventId" element={<Event />} />
            </Routes>
        </EventsContext.Provider>
    );
};