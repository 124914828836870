import { FC, useEffect, useState } from "react";
import { CustomerClient, CustomerGetAllResponse } from "AdminApi/AdminApi";
import { HttpStatusCode } from "aphrodite.react/Helpers";
import { AutoTable } from "aphrodite.react/Components";
import { useApp } from "Context/AppContext";
import { Link } from "react-router-dom";

export const Customers: FC = () => {
    const { resource } = useApp();

    const [customers, setCustomers] = useState<CustomerGetAllResponse[] | null>(null);

    const refreshCustomers = async () => {
        const response = await new CustomerClient().getAll();

        if (response.status === HttpStatusCode.Http200Ok) {
            setCustomers(response.result);
        }
    }

    useEffect(() => {
        refreshCustomers();
    }, []);

    return (
        <>
            <h1>{resource("Customers")}</h1>

            <AutoTable
                items={customers}
                rowKey={c => c.id}
                columns={[
                    {
                        header: resource("Email"),
                        display: c => (
                            <Link to={c.id}>{c.email}</Link>
                        )
                    },
                    { header: resource("FirstName"), display: c => c.firstName },
                    { header: resource("LastName"), display: c => c.lastName }
                ]}
            />
        </>
    );
};