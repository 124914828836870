// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OFnVNxa4KCUAnvyPJqVg{border:1px solid #000;border-radius:3px;background-color:#fff;padding:3px 6px;display:flex}.OFnVNxa4KCUAnvyPJqVg .FzUb6VLHJplfi86Cu541{font-weight:600;flex:0 0 200px}.OFnVNxa4KCUAnvyPJqVg .iXzvnjYXYaPd3tK0XEEA{flex:0 0 400px;display:flex;gap:6px}.OFnVNxa4KCUAnvyPJqVg .esrCH058XgYMWIPESHPr{flex:1 1 auto}`, "",{"version":3,"sources":["webpack://./Client/Components/TaskConfiguration/TaskConfiguration.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,iBAAA,CACA,qBAAA,CACA,eAAA,CACA,YAAA,CAEE,4CACE,eAAA,CACA,cAAA,CAGF,4CACE,cAAA,CACA,YAAA,CACA,OAAA,CAGF,4CACE,aAAA","sourcesContent":[".task{border:1px solid #000;border-radius:3px;background-color:#fff;padding:3px 6px;display:flex}.task .taskName{font-weight:600;flex:0 0 200px}.task .taskTime{flex:0 0 400px;display:flex;gap:6px}.task .notifications{flex:1 1 auto}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"task": `OFnVNxa4KCUAnvyPJqVg`,
	"taskName": `FzUb6VLHJplfi86Cu541`,
	"taskTime": `iXzvnjYXYaPd3tK0XEEA`,
	"notifications": `esrCH058XgYMWIPESHPr`
};
export default ___CSS_LOADER_EXPORT___;
