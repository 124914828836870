import { ConfigurationClient, Uris } from "../ConfigurationApi/ConfigurationApi";
import { HttpStatusCode } from "aphrodite.react/Helpers";

export class Configuration {
    private static globalContext: Uris;

    static get(): Uris {
        return this.globalContext;
    }

    static async refresh() {
        const client = new ConfigurationClient();
        const response = await client.getUris();
        if (response.status === HttpStatusCode.Http200Ok) {
            this.setContext(response.result)
        }
    }

    private static setContext(newContext: Uris) {
        this.globalContext = newContext;
    }
}

export interface IConfiguration {
    apiUrl: string | null;
    appUrl: string | null;
    authUrl: string | null;
    authAppId: string | null;
}
