import { Button, Form, Header, Input, Select } from "aphrodite.react/Components";
import { useValidator } from "aphrodite.react/Hooks";
import { FC, useState } from "react";
import { useApp } from "Context/AppContext";
import { DateFormatter, HttpStatusCode, Severity } from "aphrodite.react/Helpers";
import { useEvents } from "./EventsContext";
import { EventClient } from "AdminApi/AdminApi";
import { useNavigate } from "react-router-dom";
import { useAdmin } from "../AdminContext";

export const EventCreate: FC = () => {
    const { resource } = useApp();
    const { countries } = useAdmin();
    const { workflows } = useEvents();
    const navigate = useNavigate();
    const validator = useValidator();

    const [name, setName] = useState<string>("");
    const [start, setStart] = useState<string>("");
    const [workflowId, setWorkflowId] = useState<string>("");
    const [countryId, setCountryId] = useState<string>("");

    const handleSaveClicked = async () => {
        const response = await new EventClient().post({
            name,
            eventTemplateId: parseInt(workflowId),
            start: DateFormatter.isoDateTimeUtc(start),
            countryId: !!countryId ? parseInt(countryId) : null
        });

        if (response.status === HttpStatusCode.Http201Created) {
            navigate("..");
        }
    };

    return (
        <>
            <Header
                title={resource("CreateEvent")}
                right={
                    <>
                        <Button
                            severity={Severity.Success}
                            onClick={handleSaveClicked}
                        >
                            {resource("Save")}
                        </Button>
                        <Button
                            severity={Severity.Danger}
                            onClick={() => navigate(-1)}
                        >
                            {resource("Cancel")}
                        </Button>
                    </>
                }
            />

            <Form>
                <Input
                    id="name"
                    label={resource("Name")}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    validator={validator}
                    validations={{ required: true }}
                />

                <Input
                    id="start"
                    type="datetime-local"
                    label={resource("Starting")}
                    value={start}
                    onChange={(e) => setStart(e.target.value)}
                    validator={validator}
                    validations={{ required: true }}
                />

                <Select
                    id="countryId"
                    label={resource("Country")}
                    items={countries!}
                    values={c => c.id.toString()}
                    descriptions={c => c.name}
                    value={countryId}
                    onChange={(e) => setCountryId(e.target.value)}
                    validator={validator}
                    validations={{ required: false }}
                />

                <Select
                    id="workflowId"
                    label={resource("Workflow")}
                    items={workflows!}
                    values={w => w.workflowId.toString()}
                    descriptions={w => w.name}
                    value={workflowId}
                    onChange={(e) => setWorkflowId(e.target.value)}
                    validator={validator}
                    validations={{ required: true }}
                />
            </Form>
        </>
    );
}