import { FC, useEffect, useState } from "react";
import { AdminPermission, EventClient, EventGetAllResponse } from "AdminApi/AdminApi";
import { DateFormatter, HttpStatusCode, Severity } from "aphrodite.react/Helpers";
import { useApp } from "Context/AppContext";
import { AutoTable, Button, Header } from "aphrodite.react/Components";
import { Link, useNavigate } from "react-router-dom";
import { HasPermission } from "Components/HasPermission";

export const Events: FC = () => {
    const { resource } = useApp();
    const navigate = useNavigate();

    const [events, setEvents] = useState<EventGetAllResponse[] | null>(null);

    const refreshEvents = async () => {
        const response = await new EventClient().getAll();

        if (response.status === HttpStatusCode.Http200Ok) {
            setEvents(response.result);
        }
    };

    useEffect(() => {
        refreshEvents();
    }, []);

    if (!events) return null;

    return (
        <div>
            <Header
                title={resource("UpcomingEvents")}
                right={
                    <span>
                        <HasPermission permission={AdminPermission.EventCreate}>
                            <Button
                                severity={Severity.Success}
                                onClick={() => navigate("new")}
                            >
                                {resource("New")}
                            </Button>
                        </HasPermission>
                    </span>
                }
            />

            <AutoTable
                items={events}
                rowKey={e => e.id}
                columns={[
                    { header: resource("Name"), display: e => <Link to={e.id}>{e.name}</Link> },
                    {
                        header: resource("Starting"),
                        display: e => <span>{DateFormatter.localeDateLocal(e.start)} {DateFormatter.localeTimeLocal(e.start)}</span>
                    },
                    { header: resource("SignUps"), display: e => e.customerSignups },
                //    { header: resource("Workflow"), display: e => workflows?.find(w => w.workflowId === e.workflowId)?.name ?? resource("NoneValue") },
                //    { header: resource("Country"), display: e => (!!e.countryId ? countries?.find(c => c.id === e.countryId)?.name : null) ?? resource("NoneValue") }
                ]}
            />
        </div>
    );
};