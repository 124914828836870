import { useState } from "react";
import { ValidatedInput } from "./ValidatedInput";
import { DefaultValidationMessages, ValidationMessages } from "./ValidationMessages";
import { isNumeric } from "../../Utilities";
import { ValidatorHook } from "./ValidatorHook";
import { Validations } from "./Validations";

export const useValidator = (): ValidatorHook => {
    const [components, setComponents] = useState<ValidatedInput[]>([]);
    const [customErrors, setCustomErrors] = useState<{key: string, error: string}[]>([]);
    const [submitWasAttempted, setSubmitWasAttempted] = useState<boolean>(false);

    const addCustomError = (key: string, error: string) => {
        const component = components.find(c => c.key === key);
        if (component == null) return;
        setCustomErrors([...customErrors, { key, error }]);
    };

    const anyErrors = (): boolean => {
        for (const component of components) {
            if (getErrors(component.key).length > 0) return true;
        }

        return false;
    };

    const canSubmit = (): boolean => {
        setSubmitWasAttempted(true);

        for (const component of components) {
            component.forceUpdate();
        }

        return getAllErrors().length === 0;
    };

    const getErrors = (key: string): string[] => {
        const component = components.find(c => c.key === key);
        if (component == null) return [];

        const messages: ValidationMessages = !component.validationMessages
            ? DefaultValidationMessages
            : { ...DefaultValidationMessages, ...component.validationMessages };

        let errors: string[] = customErrors.filter(ce => ce.key === key).map(ce => ce.error);

        if (component.validations == null) return errors;
        const validations = component.validations;

        if (Array.isArray(component.value)) {
            errors = [...errors, ...generateArrayErrors(component, validations, messages)];
        } else {
            errors = [...errors, ...generateStringErrors(component, validations, messages)];
        }

        return errors;
    };

    const generateArrayErrors = (component: ValidatedInput, validations: Validations, messages: ValidationMessages): string[] => {
        const value = component.value as string[];
        let errors: string[] = [];

        if (validations.required) {
            if (value == null || value.length <= 0) {
                errors.push(messages.FieldRequired!);
            }
        }

        if (validations.required === false && (value == null || value.length === 0)) {
            return errors;
        }

        if (!!validations.custom) {
            errors = errors.concat(validations.custom(value));
        }

        if (!!validations.minItems) {
            if (value.length < validations.minItems) {
                errors.push(messages.TooFewItems!.replace("{minItems}", validations.minItems.toString()));
            }
        }

        if (!!validations.maxItems) {
            if (value.length > validations.maxItems) {
                errors.push(messages.TooManyItems!.replace("{maxItems}", validations.maxItems.toString()));
            }
        }

        return errors;
    };

    const generateStringErrors = (component: ValidatedInput, validations: Validations, messages: ValidationMessages): string[] => {
        const value = component.value?.toString();

        let errors: string[] = [];

        if (validations.required) {
            if (value == null || value.trim().length <= 0) {
                errors.push(messages.FieldRequired!);
            }
        }

        if (validations.required === false && (value == null || value.trim().length === 0)) {
            return errors;
        }

        if (!!validations.custom) {
            errors = errors.concat(validations.custom(value));
        }

        if (value == null || value.trim().length === 0) {
            return errors;
        }

        if (!!validations.regex && !!validations.regex.expression) {
            if (!validations.regex.expression.test(value)) {
                errors.push(validations.regex.message);
            }
        }

        if (validations.numeric) {
            if (!isNumeric(value)) {
                errors.push(messages.NotANumber!);
            }
        }

        if (validations.minValue !== undefined || validations.maxValue !== undefined) {
            if (!isNumeric(value)) {
                errors.push(messages.NotANumber!);
            }
        }

        if (isNumeric(value) && validations.minValue !== undefined) {
            if (parseFloat(value) < validations.minValue) {
                errors.push(messages.NumberTooSmall!.replace("{minValue}", validations.minValue.toString()));
            }
        }

        if (isNumeric(value) && validations.maxValue !== undefined) {
            if (parseFloat(value) > validations.maxValue) {
                errors.push(messages.NumberTooLarge!.replace("{maxValue}", validations.maxValue.toString()));
            }
        }

        if (!!validations.minLength) {
            if (value == null || value.length < validations.minLength) {
                errors.push(messages.TooShort!.replace("{minLength}", validations.minLength.toString()));
            }
        }

        if (!!validations.maxLength) {
            if (value == null || value.length > validations.maxLength) {
                errors.push(messages.TooLong!.replace("{maxLength}", validations.maxLength.toString()));
            }
        }

        if (!!validations.notIn) {
            if (validations.notIn.indexOf(value) >= 0) {
                errors.push(messages.InvalidValue!);
            }
        }

        if (!!validations.oneOf) {
            if (validations.oneOf.indexOf(value) === -1) {
                errors.push(messages.InvalidValue!);
            }
        }

        return errors;
    };

    const getAllErrors = (): string[] => {
        let allErrors: Array<string> = [];
        for (var component of components) {
            allErrors = [...allErrors, ...getErrors(component.key)];
        }
        return allErrors;
    };

    const register = (component: ValidatedInput) => {
        if (components.findIndex(c => c.key === component.key) === -1) {
            setComponents(components => [...components, component]);
        }
    };

    const unregister = (key: string) => {
        setComponents(components.filter(c => c.key !== key));
    };

    const removeCustomErrors = (key: string) => {
        setCustomErrors(customErrors.filter(ce => ce.key !== key));
    };

    const reset = () => {
        setSubmitWasAttempted(false);
    };

    const setValue = (key: string, value: string | string[] | null) => {
        const component = components.find(c => c.key === key);
        if (component == null) return;
        component.value = value;
        removeCustomErrors(key);
    };

    const submitAttempted = (): boolean => {
        return submitWasAttempted;
    };

    return {
        addCustomError,
        anyErrors,
        canSubmit,
        getErrors,
        getAllErrors,
        register,
        removeCustomErrors,
        reset,
        setValue,
        submitAttempted,
        unregister
    };
};