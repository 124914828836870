// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../Aphrodite.Admin.Web/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../Aphrodite.Admin.Web/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Yxd8P7AV4YBs2FYPBpH7{display:flex;align-content:center;align-items:center;justify-content:center;flex-wrap:wrap;z-index:5000;background-color:#888;background-color:rgba(0,0,0,.4)}.Yxd8P7AV4YBs2FYPBpH7.QaMXuAAuqoR3X_ZBgSlb{position:fixed;top:0;left:0;right:0;bottom:0}.Yxd8P7AV4YBs2FYPBpH7.DvVy0kLR8y62f6lvHaeg{position:absolute;top:0;left:0;right:0;bottom:0}`, "",{"version":3,"sources":["webpack://./../Aphrodite.React/Components/CenterCover/CenterCover.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,oBAAA,CACA,kBAAA,CACA,sBAAA,CACA,cAAA,CACA,YAAA,CACA,qBAAA,CACA,+BAAA,CAEA,2CACI,cAAA,CACA,KAAA,CACA,MAAA,CACA,OAAA,CACA,QAAA,CAGJ,2CACI,iBAAA,CACA,KAAA,CACA,MAAA,CACA,OAAA,CACA,QAAA","sourcesContent":[".cover{display:flex;align-content:center;align-items:center;justify-content:center;flex-wrap:wrap;z-index:5000;background-color:#888;background-color:rgba(0,0,0,.4)}.cover.fullScreen{position:fixed;top:0;left:0;right:0;bottom:0}.cover.relative{position:absolute;top:0;left:0;right:0;bottom:0}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cover": `Yxd8P7AV4YBs2FYPBpH7`,
	"fullScreen": `QaMXuAAuqoR3X_ZBgSlb`,
	"relative": `DvVy0kLR8y62f6lvHaeg`
};
export default ___CSS_LOADER_EXPORT___;
