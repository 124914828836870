import * as React from "react";
import { InputCommonProps } from "./Common";
import commonStyles from "./Common.module.scss";
import { classNames } from "../../Utilities";

interface NoInputProps {
    value: React.ReactNode;
}

export const NoInput: React.FC<NoInputProps & InputCommonProps> = (props) => {
    return (
        <div className={commonStyles.container}>

            {!!props.label && (
                <div className={classNames([commonStyles.label])}>
                    {props.label}
                </div>
            )}

            <div className={commonStyles.input}>
                <div>
                    {props.value}
                </div>
            </div>

        </div>
    );
};