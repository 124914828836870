import { faLanguage, faUsers, faProjectDiagram, faListCheck } from "@fortawesome/free-solid-svg-icons";
import { FC } from "react";
import { Tile } from "Components/Tile/Tile";
import { useApp } from "Context/AppContext";
import { useNavigate } from "react-router-dom";
import { faCalendar, faFaceKissWinkHeart, faImages } from "@fortawesome/free-regular-svg-icons";
import { HasAnyPermission, HasPermission } from "Components/HasPermission";
import styles from "./Admin.module.scss";
import { AdminPermission } from "AdminApi/AdminApi";
import { Header } from "aphrodite.react/Components";

export const Admin: FC = () => {
    const { resource } = useApp();
    const navigate = useNavigate();

    return (
        <div>
            <Header title={resource("Users")} />
            <div className={styles.tiles}>
                <HasPermission permission={AdminPermission.StaffRead}>
                    <Tile icon={faUsers} title={resource("Staff")} onClick={() => navigate("staff")} />
                </HasPermission>
                <HasPermission permission={AdminPermission.CustomerRead}>
                    <Tile icon={faFaceKissWinkHeart} title={resource("Customers")} onClick={() => navigate("customers")} />
                </HasPermission>
            </div>

            <Header title={resource("Events")} />
            <div className={styles.tiles}>
                <HasPermission permission={AdminPermission.EventRead}>
                    <Tile icon={faCalendar} title={resource("Events")} onClick={() => navigate("events")} />
                </HasPermission>
                <HasPermission permission={AdminPermission.EventTemplateRead}>
                    <Tile icon={faListCheck} title={resource("EventTemplates")} onClick={() => navigate("eventtemplates")} />
                </HasPermission>
                <HasPermission permission={AdminPermission.WorkflowRead}>
                    <Tile icon={faProjectDiagram} title={resource("Workflows")} onClick={() => navigate("workflows")} />
                </HasPermission>
            </div>

            <Header title={resource("System")} />
            <div className={styles.tiles}>
                <HasAnyPermission
                    permissions={[AdminPermission.LanguageCreate, AdminPermission.LanguageUpdate, AdminPermission.LanguageDelete]}
                >
                    <Tile icon={faLanguage} title={resource("Languages")} onClick={() => navigate("languages")} />
                </HasAnyPermission>
                <HasPermission permission={AdminPermission.EventImageRead}>
                    <Tile icon={faImages} title={resource("EventImages")} onClick={() => navigate("eventimages")} />
                </HasPermission>
            </div>
        </div>
    );
};