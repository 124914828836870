import { FC, useEffect, useState } from "react";
import { useApp } from "Context/AppContext";
import { AutoTable, Button, Header } from "aphrodite.react/Components";
import { EventImagesClient, StorageFileGetResponse } from "AdminApi/AdminApi";
import { HttpStatusCode, Severity } from "aphrodite.react/Helpers";
import styles from "./EventImages.module.scss";
import { EventImageUploadModal } from "Modals/EventImageUploadModal";

export const EventImages: FC = () => {
    const { resource } = useApp();

    const [images, setImages] = useState<StorageFileGetResponse[] | null>(null);
    const [uploadFileModalOpen, setUploadFileModalOpen] = useState<boolean>(false);

    const refreshImages = async () => {
        const response = await new EventImagesClient().getAll(0, null);

        if (response.status === HttpStatusCode.Http200Ok) {
            setImages(response.result.results);
        }
    };

    const handleModalClosed = (dirty: boolean) => {
        setUploadFileModalOpen(false);
        if (dirty) {
            refreshImages();
        }
    };

    useEffect(() => {
        refreshImages();
    }, []);

    return (
        <>
            <Header
                title={resource("EventImages")}
                right={
                    <span>
                        <Button
                            onClick={() => setUploadFileModalOpen(true)}
                            severity={Severity.Success}
                        >
                            {resource("Upload")}
                        </Button>
                    </span>
                }
            />

            <AutoTable
                items={images}
                rowKey={i => i.id}
                className={styles.table}
                columns={[
                    { header: resource("Name"), display: i => i.name },
                    { header: resource("Preview"), className: styles.image, display: i => <img src={i.uri!} /> }
                ]}
            />

            {uploadFileModalOpen && (
                <EventImageUploadModal
                    onClose={handleModalClosed}
                />
            ) }
        </>
    );
};