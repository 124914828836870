import { FC, useEffect, useState } from "react";
import { EventTemplatesContext } from "./EventTemplatesContext";
import { Route, Routes } from "react-router-dom";
import { GenderClient, GenderGetAllResponse, OrientationClient, OrientationGetAllResponse, WorkflowClient, WorkflowGetAllResponse } from "AdminApi/AdminApi";
import { HttpStatusCode } from "aphrodite.react/Helpers";
import { EventTemplates } from "./EventTemplates";
import { EventTemplateCreate } from "./EventTemplateCreate";

export const EventTemplatesArea: FC = () => {
    const [workflows, setWorkflows] = useState<WorkflowGetAllResponse[] | null>(null);
    const [genders, setGenders] = useState<GenderGetAllResponse[] | null>(null);
    const [orientations, setOrientations] = useState<OrientationGetAllResponse[] | null>(null);

    const refreshWorkflows = async () => {
        const response = await new WorkflowClient().getAll();

        if (response.status === HttpStatusCode.Http200Ok) {
            setWorkflows(response.result);
        }
    };

    const refreshGenders = async () => {
        const response = await new GenderClient().getAll();

        if (response.status === HttpStatusCode.Http200Ok) {
            setGenders(response.result);
        }
    };

    const refreshOrientations = async () => {
        const response = await new OrientationClient().getAll();

        if (response.status === HttpStatusCode.Http200Ok) {
            setOrientations(response.result);
        }
    };

    useEffect(() => {
        refreshWorkflows();
        refreshGenders();
        refreshOrientations();
    }, []);

    if (!workflows || !genders || !orientations) return null;

    return (
        <EventTemplatesContext.Provider value={{ workflows, genders, orientations }}>
            <Routes>
                <Route index element={<EventTemplates />} />
                <Route path="new" element={<EventTemplateCreate />} />
            </Routes>
        </EventTemplatesContext.Provider>
    );
}