// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../Aphrodite.Admin.Web/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../Aphrodite.Admin.Web/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wxAl2PlR6wjhh9jAUViU{resize:none;font-family:inherit}.YcQFANj0sowdx_t0JgmA{display:grid;width:100%}.YcQFANj0sowdx_t0JgmA::after{content:attr(data-replicated-value) " ";white-space:pre-wrap;visibility:hidden;grid-area:1/1/2/2;padding:10px 16px}.YcQFANj0sowdx_t0JgmA>textarea{resize:none;overflow:hidden;grid-area:1/1/2/2}`, "",{"version":3,"sources":["webpack://./../Aphrodite.React/Components/Inputs/TextArea.module.scss"],"names":[],"mappings":"AAAA,sBACI,WAAA,CACA,mBAAA,CAGJ,sBACI,YAAA,CACA,UAAA,CAEA,6BAEI,uCAAA,CAEA,oBAAA,CAEA,iBAAA,CACA,iBAAA,CACA,iBAAA,CAGJ,+BAEI,WAAA,CAEA,eAAA,CACA,iBAAA","sourcesContent":[".textArea{resize:none;font-family:inherit}.growWrap{display:grid;width:100%}.growWrap::after{content:attr(data-replicated-value) \" \";white-space:pre-wrap;visibility:hidden;grid-area:1/1/2/2;padding:10px 16px}.growWrap>textarea{resize:none;overflow:hidden;grid-area:1/1/2/2}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textArea": `wxAl2PlR6wjhh9jAUViU`,
	"growWrap": `YcQFANj0sowdx_t0JgmA`
};
export default ___CSS_LOADER_EXPORT___;
