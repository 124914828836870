import { FC, PropsWithChildren } from "react";
import { AdminPermission } from "AdminApi/AdminApi";
import { hasPermission } from "Helpers/Permissions";
import { useApp } from "Context/AppContext";

interface HasPermissionProps {
    permission: AdminPermission;
}

export const HasPermission: FC<PropsWithChildren<HasPermissionProps>> = (props) => {
    const { session } = useApp();

    if (hasPermission(session, props.permission)) return props.children;

    return null;
};