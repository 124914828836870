import { FC, ReactNode } from "react";
import styles from "./Header.module.scss";

interface HeaderProps {
    title: string;
    level?: number;
    right?: JSX.Element;
}

export const Header: FC<HeaderProps> = (props) => {
    const getHeading = (): ReactNode => {
        switch (props.level) {
            case 2:
                return <h2>{props.title}</h2>;
            case 3:
                return <h3>{props.title}</h3>;
            case 4:
                return <h4>{props.title}</h4>;
            case 5:
                return <h5>{props.title}</h5>;
            case 6:
                return <h6>{props.title}</h6>;
            default:
                return <h1>{props.title}</h1>;
        }
    };

    return (
        <div className={styles.split}>
            <div>
                {getHeading()}
            </div>
            {!!props.right && (
                <div className={styles.right}>
                    {props.right}
                </div>
            )}
        </div>
    );
};