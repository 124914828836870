import { Button, Input } from "aphrodite.react/Components";
import { ChangeEvent, FC, useState } from "react";
import { ResourceValueGetResponse, ResourceValuePutRequest } from "AdminApi/AdminApi";
import { useValidator } from "aphrodite.react/Hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk, faRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { useApp } from "Context/AppContext";
import styles from "./ResourceEditor.module.scss";
import { Severity } from "aphrodite.react/Helpers";

interface ResourceEditorProps {
    resource: ResourceValueGetResponse;
    onUpdate: (id: string, request: ResourceValuePutRequest) => void;
}

export const ResourceEditor: FC<ResourceEditorProps> = (props) => {
    const { resource } = useApp();
    const validator = useValidator();

    const [value, setValue] = useState<string>(props.resource.value ?? "");
    const [dirty, setDirty] = useState<boolean>(false);

    const handleValueChanged = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
        !dirty && setDirty(true);
    };

    const handleUpdateClicked = () => {
        if (!validator.canSubmit()) return;

        const request: ResourceValuePutRequest = {
            value
        };

        props.onUpdate(props.resource.id, request);

        setDirty(false);
    };

    const handleResetClicked = () => {
        setValue(props.resource.value ?? "");
        setDirty(false);
        validator.reset();
    }

    return (
        <div className={styles.resourceEditor}>
            <Input
                id={props.resource.id}
                value={value}
                onChange={handleValueChanged}
                validator={validator}
                validations={{ required: true, minLength: 1 }}
            />
            <span>
                <Button
                    onClick={() => handleUpdateClicked()}
                    title={resource("Save")}
                    disabled={!dirty}
                    severity={Severity.Success}
                >
                    <FontAwesomeIcon
                        icon={faFloppyDisk}
                    />
                </Button>
                <Button
                    onClick={() => handleResetClicked()}
                    title={resource("Reset")}
                    disabled={!dirty}
                    severity={Severity.Warning}
                >
                    <FontAwesomeIcon
                        icon={faRotateLeft}
                    />
                </Button>
            </span>
        </div>
    );
};