import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useApp } from "Context/AppContext";
import { AdminPermission, StaffClient, StaffGetResponse } from "AdminApi/AdminApi";
import styles from "./StaffMember.module.scss";
import { Button, Header, NoInput } from "aphrodite.react/Components";
import { HttpStatusCode, Severity, enumToArray } from "aphrodite.react/Helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { StaffMemberEditModal } from "Modals/StaffEditModal";
import { useAdmin } from "../AdminContext";
import { HasPermission } from "Components/HasPermission";

type StaffMemberParams = {
    staffId: string;
};

export const StaffMember: FC = () => {
    const navigate = useNavigate();
    const { resource, languages } = useApp();
    const { countries } = useAdmin();
    const params = useParams<StaffMemberParams>();

    const [staffMember, setStaffMember] = useState<StaffGetResponse | null>(null);
    const [edit, setEdit] = useState(false);

    const permissions = enumToArray(AdminPermission);

    const refreshStaffMember = async () => {
        const response = await new StaffClient().get(params.staffId!);

        if (response.status === HttpStatusCode.Http200Ok) {
            setStaffMember(response.result);
        }
    };

    const handleDeleteClicked = async () => {
        if (!staffMember) return;

        if (confirm(resource("StaffMemberDeleteConfirmation", { 1: staffMember.email }))) {
            const response = await new StaffClient().delete(staffMember.id);
            if (response.status === HttpStatusCode.Http204NoContent) {
                navigate(-1);
            }
        }
    }

    const handleModalClosed = (dirty: boolean) => {
        setEdit(false);

        if (dirty) {
            refreshStaffMember();
        }
    };

    useEffect(() => {
        refreshStaffMember();
    }, []);

    if (!staffMember) return null;

    return (
        <>
            <div>
                <Header
                    title={resource("StaffMemberDetails")}
                    right={
                        <span>
                            <HasPermission permission={AdminPermission.StaffUpdate}>
                                <Button severity={Severity.Warning} onClick={() => setEdit(true)}>{resource("Edit")}</Button>
                            </HasPermission>
                            <HasPermission permission={AdminPermission.StaffDelete}>
                                <Button severity={Severity.Danger} onClick={() => handleDeleteClicked()}>{resource("Delete")}</Button>
                            </HasPermission>
                        </span>
                    }
                />

                <div className={styles.split}>

                    <div className={styles.left}>
                        <h2>{resource("Essentials")}</h2>

                        <NoInput label={resource("Name")} value={staffMember.name} />
                        <NoInput label={resource("Email")} value={staffMember.email} />
                        <NoInput label={resource("Country")} value={countries?.find(c => c.id === staffMember.countryId)?.name ?? resource("NoneValue")} />
                        <NoInput label={resource("Language")} value={languages?.find(l => l.id === staffMember.languageId)?.localisedName ?? resource("NoneValue")} />
                    </div>

                    <div className={styles.right}>
                        <h2>{resource("Permissions")}</h2>

                        <ul>
                            {!!staffMember.globalOwner &&
                                <li><FontAwesomeIcon icon={faStar} /> {resource("GlobalOwner")}</li>
                            }
                            {!staffMember.globalOwner &&
                                staffMember.permissions?.map(p =>
                                    <li>{permissions.find(permission => permission.value === p)?.key}</li>
                                )
                            }
                        </ul>
                    </div>

                </div>
            </div>

            {edit && (
                <StaffMemberEditModal
                    onClose={handleModalClosed}
                    staffMemberId={params.staffId}
                />
            )}
        </>
    );
}