// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../Aphrodite.Admin.Web/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../Aphrodite.Admin.Web/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SPdDUXPy444E1ATJoMrm{display:flex;justify-content:space-between;align-items:center}.SPdDUXPy444E1ATJoMrm .on9Lj2U1CzwVctKamwUK{display:flex;align-items:center;gap:8px}`, "",{"version":3,"sources":["webpack://./../Aphrodite.React/Components/Header/Header.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,6BAAA,CACA,kBAAA,CAEA,4CACI,YAAA,CACA,kBAAA,CACA,OAAA","sourcesContent":[".split{display:flex;justify-content:space-between;align-items:center}.split .right{display:flex;align-items:center;gap:8px}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"split": `SPdDUXPy444E1ATJoMrm`,
	"right": `on9Lj2U1CzwVctKamwUK`
};
export default ___CSS_LOADER_EXPORT___;
