import { createContext, useContext } from "react";
import { CountryGetAllResponse, WorkflowGetAllResponse } from "AdminApi/AdminApi";

interface EventsContextState {
    workflows: WorkflowGetAllResponse[] | null;
}

export const EventsContext = createContext<EventsContextState>({
    workflows: null
});

export function useEvents() {
    return useContext(EventsContext);
}