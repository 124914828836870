import { createContext, useContext } from "react";
import { Dictionary } from "aphrodite.react/Helpers";
import { CountryGetAllResponse, LanguageGetResponse, SessionGetResponse } from "AdminApi/AdminApi";

interface AppContextState {
    resource: (key: string, variables?: Dictionary<string | number>) => string;
    session: SessionGetResponse | null;
    setSession: (session: SessionGetResponse | null) => void;
    languages: LanguageGetResponse[] | null;
    refreshLanguages: () => void;
}

export const AppContext = createContext<AppContextState>({
    resource: () => {
        return "No resource finder has been implemented";
    },
    session: null,
    setSession: () => { },
    languages: null,
    refreshLanguages: () => { }
});

export function useApp() {
    return useContext(AppContext);
}
