import { Input } from "aphrodite.react/Components";
import { FC, useState } from "react";
import styles from "./TaskConfiguration.module.scss";

interface TaskConfigurationProps {
    name: string;
    notifications?: TaskNotification[];
    isBasis?: boolean;
}

interface TaskNotification {
    id: number;
    target: string;
}

export const TaskConfiguration: FC<TaskConfigurationProps> = (props) => {
    const [notificationsEnabled, setNotificationsEnabled] = useState<number[]>([]);

    const toggleNotification = (id: number) => {
        if (notificationsEnabled.includes(id)) {
            setNotificationsEnabled(notificationsEnabled.filter(x => x !== id));
        } else {
            setNotificationsEnabled([...notificationsEnabled, id]);
        }
    };

    return (
        <div className={styles.task}>
            <div className={styles.taskName}>{props.name}</div>
            <div className={styles.taskTime}>
                {!props.isBasis && (
                    <span className={styles.day}>
                        Day:  <input type="number" />
                    </span>
                )}
                <span className={styles.time}>
                    Time: <input type="time" />
                </span>
            </div>
            {!!props.notifications?.length && (
                <div className={styles.notifications}>
                    {props.notifications.map((notification) => (
                        <div className={styles.notification} key={notification.id}>
                            <div>
                                <input
                                    type="checkbox"
                                    id={`${props.name}-notification-${notification.id}`}
                                    checked={notificationsEnabled.includes(notification.id)}
                                    onChange={() => toggleNotification(notification.id)}
                                />
                                <label htmlFor={`${props.name}-notification-${notification.id}`}> {notification.target}</label>
                            </div>
                            {notificationsEnabled.includes(notification.id) && (
                                <div>
                                    <Input id="publishEventNotification" label="Notification Text" />
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};