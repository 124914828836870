import * as React from "react";
import styles from "./Button.module.scss";
import { classNames } from "../../Utilities";
import { Severity } from "../../Helpers";
import { IconDefinition, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ButtonProps {
    icon?: IconDefinition;
    isBusy?: () => boolean;
    severity?: Severity;
    busyText?: React.ReactNode;
    size?: ButtonSize;
}

export enum ButtonSize {
    Small,
    Standard,
    Large
}

export const Button: React.FC<React.PropsWithChildren<React.ButtonHTMLAttributes<HTMLButtonElement> & ButtonProps>> = (props) => {
    const { icon, isBusy, severity, busyText, className, ...rest } = props;

    const getSeverityClass = (): string => {
        switch (props.severity) {
            case Severity.Secondary:
                return styles.secondary;
            case Severity.Info:
                return styles.info;
            case Severity.Success:
                return styles.success;
            case Severity.Warning:
                return styles.warning;
            case Severity.Danger:
                return styles.danger;
            default:
                return styles.primary;
        }
    };

    const getSizeClass = (): string => {
        switch (props.size) {
            case ButtonSize.Small:
                return styles.small;
            case ButtonSize.Standard:
                return styles.standard;
            case ButtonSize.Large:
                return styles.large;
            default:
                return styles.standard;
        }
    };

    return (
        <button
            className={classNames([
                styles.button,
                getSeverityClass(),
                getSizeClass(),
                props.className
            ])}
            {...rest}
        >

            {!props.isBusy?.() && (
                <span>
                    {!!props.icon && (
                        <><FontAwesomeIcon icon={props.icon} />{" "}</>
                    )}
                    {props.children}
                </span>
            )}

            {!!props.isBusy?.() && (
                <span>
                    <FontAwesomeIcon icon={faSpinner} spinPulse />
                    {!!props.busyText && (
                        <>{" "}{props.busyText}</>
                    )}
                </span>
            )}

        </button>
    );
};