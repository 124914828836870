export interface ValidationMessages {
  FieldRequired?: string;
  NotANumber?: string;
  NumberTooSmall?: string;
  NumberTooLarge?: string;
  TooShort?: string;
  TooLong?: string;
  TooFewItems?: string;
  TooManyItems?: string;
  InvalidValue?: string;
}

export class DefaultValidationMessages implements ValidationMessages {
  static FieldRequired = "This field is required";
  static NotANumber = "This field must contain a valid number";
  static NumberTooSmall = "This value must be {minValue} or higher";
  static NumberTooLarge = "This value must be {maxValue} or lower";
  static TooShort = "This field must have at least {minLength} characters";
  static TooLong = "This field must have at most {maxLength} characters";
  static TooFewItems = "You must select at least {minItems} items";
  static TooManyItems = "You must select at most {maxItems} items";
  static InvalidValue = "This field has an invalid value";
}