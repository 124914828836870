import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CustomerClient, CustomerGetResponse, CustomerProfileClient, CustomerProfileGetResponse } from "AdminApi/AdminApi";
import { DateFormatter, HttpStatusCode } from "aphrodite.react/Helpers";
import { NoInput, TextArea } from "aphrodite.react/Components";
import { useApp } from "Context/AppContext";
import styles from "./Customer.module.scss";
import { useAdmin } from "../AdminContext";
import { useCustomers } from "./CustomersContext";

type CustomerParams = {
    customerId: string;
};

export const Customer: FC = () => {
    const { resource, languages } = useApp();
    const { countries } = useAdmin();
    const { genders } = useCustomers();
    const params = useParams<CustomerParams>();

    const [customer, setCustomer] = useState<CustomerGetResponse | null>(null);
    const [profile, setProfile] = useState<CustomerProfileGetResponse | null | undefined>(null);

    const refreshCustomer = async () => {
        const response = await new CustomerClient().get(params.customerId!);

        if (response.status === HttpStatusCode.Http200Ok) {
            setCustomer(response.result);
        }
    };

    const refreshProfile = async () => {
        const response = await new CustomerProfileClient().get(params.customerId!);

        switch (response.status) {
            case HttpStatusCode.Http200Ok:
                setProfile(response.result);
                break;
            case HttpStatusCode.Http404NotFound:
                setProfile(undefined);
                break;
        }
    };

    useEffect(() => {
        refreshCustomer();
        refreshProfile();
    }, []);

    if (!customer) return null;

    return (
        <div>
            <h1>{resource("CustomerDetails")}</h1>

            <div className={styles.split}>

                <div className={styles.left}>
                    <h2>{resource("Essentials")}</h2>

                    <NoInput label={resource("FirstName")} value={customer.firstName} />
                    <NoInput label={resource("LastName")} value={customer.lastName} />
                    <NoInput label={resource("BirthDate")} value={!customer.birthDate ? resource("NoneValue") : DateFormatter.localeDateLiteral(customer.birthDate)} />
                    <NoInput label={resource("NickName")} value={customer.nickName ?? resource("NoneValue")} />
                    <NoInput label={resource("Country")} value={countries?.find(c => c.id === customer.countryId)?.name ?? resource("NoneValue")} />
                </div>

                <div className={styles.right}>
                    <h2>{resource("ContactDetails")}</h2>
                    <NoInput label={resource("Email")} value={customer.email} />
                    <NoInput label={resource("Mobile")} value={customer.mobilePhone ?? resource("NoneValue")} />
                    <NoInput label={resource("Language")} value={languages?.find(l => l.id === customer.languageId)?.localisedName ?? resource("NoneValue")} />
                </div>

            </div>

            <h1>{resource("CustomerProfile")}</h1>

            {profile === undefined && (
                <p>{resource("CustomerNoProfileCreated")}</p>
            )}

            {!!profile && (
                <>
                    <NoInput
                        label={resource("Gender")}
                        value={!profile.genderId ? resource("NoneValue") : resource(genders!.find(g => g.id === profile.genderId)!.resourceId)}
                    />

                    <TextArea
                        id="bio"
                        grow={true}
                        label={resource("Bio")}
                        value={profile.bio ?? ""}
                        readOnly
                    />
                </>
            )}

        </div>
    );
};
