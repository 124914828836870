import { FC, useEffect, useState } from "react";
import { Dictionary, HttpStatusCode } from "aphrodite.react/Helpers";
import { AppContext } from "Context/AppContext";
import { LanguageClient, LanguageGetResponse, ResourceClient, SessionGetResponse } from "AdminApi/AdminApi";
import { Layout } from "./Layout";
import { Navigate, Route, Routes } from "react-router-dom";
import { Login } from "Pages/Login";
import { AdminArea } from "Pages/Admin/AdminArea";
import { Language } from "Helpers/Language";

export const App: FC = () => {
    const [resources, setResources] = useState<Dictionary<string> | null>(null);
    const [session, setSession] = useState<SessionGetResponse | null>(null);
    const [languages, setLanguages] = useState<LanguageGetResponse[] | null>(null);

    const getResources = async () => {
        const languageId = await Language.get();
        const client = new ResourceClient();
        const response = await client.get(languageId);
        if (response.status === HttpStatusCode.Http200Ok) {
            setResources(response.result);
        }
    };

    const refreshLanguages = async () => {
        const response = await new LanguageClient().getAll();

        if (response.status === HttpStatusCode.Http200Ok) {
            setLanguages(response.result);
        }
    };


    const resource = (key: string, variables?: Dictionary<string | number>) => {
        if (!!resources) {
            const result = resources[key];

            if (!!result) {
                let value = result;
                if (!!variables) {
                    Object.keys(variables).forEach(k => {
                        value = value.split(`{${k}}`).join(variables[k].toString());
                    });
                }

                return value;
            }
        }

        return `{{${key}}}`;
    };

    useEffect(() => {
        getResources();
        refreshLanguages();
    }, []);

    if (!resources) return null;

    return (
        <AppContext.Provider value={{ resource, session, setSession, languages, refreshLanguages }}>
            <Layout>
                <Routes>
                    <Route index element={<Navigate to="admin" />} />
                    <Route path="login/*" element={<Login />} />
                    <Route path="admin/*" element={<AdminArea />} />
                </Routes>
            </Layout>
        </AppContext.Provider>
    );
};