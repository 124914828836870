// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cy34ylT0MTpjMZeR8u39{display:flex}.cy34ylT0MTpjMZeR8u39 .zhpveFHkpwRJ9LPiDOhL{flex:1 1 auto}.cy34ylT0MTpjMZeR8u39 .GUXbV9q2bp_TbG9TmL7L{flex:1 1 auto}`, "",{"version":3,"sources":["webpack://./Client/Pages/Admin/Customers/Customer.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CAEA,4CACI,aAAA,CAGJ,4CACI,aAAA","sourcesContent":[".split{display:flex}.split .left{flex:1 1 auto}.split .right{flex:1 1 auto}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"split": `cy34ylT0MTpjMZeR8u39`,
	"left": `zhpveFHkpwRJ9LPiDOhL`,
	"right": `GUXbV9q2bp_TbG9TmL7L`
};
export default ___CSS_LOADER_EXPORT___;
