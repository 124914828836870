import { AdminPermission, SessionGetResponse } from "AdminApi/AdminApi";

export const hasPermission = (session: SessionGetResponse | null, permission: AdminPermission): boolean => {
    if (session?.globalOwner) return true;

    return session?.permissions?.includes(permission) ?? false;
};

export const hasAllPermissions = (session: SessionGetResponse | null, permissions: AdminPermission[]): boolean => {
    if (session?.globalOwner) return true;

    for (let permission of permissions) {
        if (!session?.permissions?.includes(permission)) return false;
    }

    return true;
};

export const hasAnyPermission = (session: SessionGetResponse | null, permissions: AdminPermission[]): boolean => {
    if (session?.globalOwner) return true;

    for (let permission of permissions) {
        if (session?.permissions?.includes(permission)) return true;
    }

    return false;
};