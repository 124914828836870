// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Eoby1oX7Ybi0XpQnjWsD{display:flex}.Eoby1oX7Ybi0XpQnjWsD .KJEwvKkPIoxOYY4Z6pUz{flex:1 1 auto}.Eoby1oX7Ybi0XpQnjWsD .IX6dUIpDTMxVBHpIUUlB{flex:1 1 auto}`, "",{"version":3,"sources":["webpack://./Client/Pages/Admin/Staff/StaffMember.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CAEA,4CACI,aAAA,CAGJ,4CACI,aAAA","sourcesContent":[".split{display:flex}.split .left{flex:1 1 auto}.split .right{flex:1 1 auto}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"split": `Eoby1oX7Ybi0XpQnjWsD`,
	"left": `KJEwvKkPIoxOYY4Z6pUz`,
	"right": `IX6dUIpDTMxVBHpIUUlB`
};
export default ___CSS_LOADER_EXPORT___;
