import { AutoTable, Button, Header } from "aphrodite.react/Components";
import { FC, useEffect, useState } from "react";
import { useApp } from "Context/AppContext";
import { AdminPermission, EventTemplateClient, EventTemplateGetAllResponse } from "AdminApi/AdminApi";
import { HttpStatusCode, Severity } from "aphrodite.react/Helpers";
import { HasPermission } from "Components/HasPermission";
import { useNavigate } from "react-router-dom";

export const EventTemplates: FC = () => {
    const { resource } = useApp();
    const navigate = useNavigate();

    const [eventTemplates, setEventTemplates] = useState<EventTemplateGetAllResponse[] | null>(null);

    const refreshEventTemplates = async () => {
        const response = await new EventTemplateClient().getAll();

        if (response.status === HttpStatusCode.Http200Ok) {
            setEventTemplates(response.result);
        }
    };

    useEffect(() => {
        refreshEventTemplates();
    }, []);

    return (
        <>
            <Header
                title={resource("EventTemplates")}
                right={
                    <span>
                        <HasPermission permission={AdminPermission.EventTemplateCreate}>
                            <Button
                                severity={Severity.Success}
                                onClick={() => navigate("new")}
                            >
                                {resource("New")}
                            </Button>
                        </HasPermission>
                    </span>
                }
            />

            <AutoTable
                items={eventTemplates}
                rowKey={e => e.id.toString()}
                columns={[
                    { header: resource("Name"), display: e => e.internalName},
                    { header: resource("Workflow"), display: e => e.workflowName }
                ]}
            />
        </>
    );
};