import { GenderGetAllResponse } from "AdminApi/AdminApi";
import { createContext, useContext } from "react";

interface CustomersContextState {
    genders: GenderGetAllResponse[] | null;
    refreshGenders: () => void;
}

export const CustomersContext = createContext<CustomersContextState>({
    genders: null,
    refreshGenders: () => { }
});

export function useCustomers() {
    return useContext(CustomersContext);
}