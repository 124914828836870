import { AutoTable, Header } from "aphrodite.react/Components";
import { FC, useEffect, useState } from "react";
import { useApp } from "Context/AppContext";
import { VersionHistoryClient, VersionHistoryGetAllResponse } from "AdminApi/AdminApi";
import { DateFormatter, HttpStatusCode } from "aphrodite.react/Helpers";

export const VersionHistory: FC = () => {
    const { resource } = useApp();

    const [versionHistory, setVersionHistory] = useState<VersionHistoryGetAllResponse[] | null>(null);

    const refreshVersionHistory = async () => {
        const response = await new VersionHistoryClient().getAll();
        if (response.status === HttpStatusCode.Http200Ok) {
            setVersionHistory(response.result);
        }
    };

    useEffect(() => {
        refreshVersionHistory();
    }, []);

    if (!versionHistory) return null;

    return (
        <>
            <Header title={resource("VersionHistory")} />

            <AutoTable
                items={versionHistory}
                rowKey={e => e.version}
                columns={[
                    { header: resource("Version"), display: e => e.version },
                    { header: resource("VersionHistoryReleaseDate"), display: e => DateFormatter.localeDateLocal(e.releaseDate) },
                    { header: resource("VersionHistoryDescription"), display: e => e.description }
                ]}
            />
        </>
    );
};