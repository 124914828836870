import { FC, PropsWithChildren } from "react";
import { useApp } from "./Context/AppContext";
import styles from "./Layout.module.scss";
import { useNavigate } from "react-router-dom";
import { Button } from "aphrodite.react/Components";
import { HttpStatusCode, Severity } from "aphrodite.react/Helpers";
import { SessionClient } from "./AdminApi/AdminApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDoorOpen } from "@fortawesome/free-solid-svg-icons";
import { Configuration } from "./Context/Configuration";

export const Layout: FC<PropsWithChildren> = (props) => {
    const { resource, session } = useApp();
    const navigate = useNavigate();

    const handleSignOut = async () => {
        const response = await new SessionClient().delete();

        if (response.status === HttpStatusCode.Http204NoContent) {
            navigate("/login");
        }
    };

    return (
        <>
            <div className={styles.topBar}>
                <div className={styles.left}>
                    <span className={styles.text} onClick={() => navigate("/admin")}>
                        {resource("AdminTitle", { 1: resource("ApplicationName") })}
                    </span>
                </div>

                <div className={styles.right}>
                    {!!session && (
                        <>
                            <span>{session.name}</span>
                            <span>
                                <Button
                                    severity={Severity.Danger}
                                    onClick={() => handleSignOut()}
                                    title={resource("SignOut")}
                                >
                                    <FontAwesomeIcon icon={faDoorOpen} />
                                </Button>
                            </span>
                        </>
                    )}
                    <Button
                        onClick={() => window.open(Configuration.get().appUri!, "_blank")}
                        severity={Severity.Primary}
                    >
                        {resource("ClientApp")}
                    </Button>
                </div>
            </div>

            <div className={styles.content}>
                {props.children}
            </div>
        </>
    );
};