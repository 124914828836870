import { ChangeEvent, FC, useCallback, useMemo, useRef, useState } from "react";
import { useApp } from "../Context/AppContext";
import { Button, Input, Modal } from "aphrodite.react/Components";
import { ModalSize } from "aphrodite.react/Components/Modal/Modal";
import { useValidator } from "aphrodite.react/Hooks";
import { EventImagesClient } from "../AdminApi/AdminApi";
import { HttpStatusCode } from "aphrodite.react/Helpers";

interface EventImageUploadModalProps {
    onClose: (dirty: boolean) => void;
}

export const EventImageUploadModal: FC<EventImageUploadModalProps> = (props) => {
    const { resource } = useApp();
    const validator = useValidator();

    const [name, setName] = useState<string>("");
    const [file, setFile] = useState<File | null>(null);
    const fileRef = useRef(file);

    const handleFileChanged = (e: ChangeEvent<HTMLInputElement>) => {
        if (!!e.target.files?.length) {
            setFile(e.target.files[0]);
            fileRef.current = e.target.files[0];
        }
    };

    const handleUploadClicked = async () => {
        if (!validator.canSubmit()) return;

        const response = await new EventImagesClient().post({ data: file!, fileName: file!.name }, name);

        if (response.status === HttpStatusCode.Http201Created) {
            props.onClose(true);
        }
    }

    const validateFile = () => {
        if (!fileRef.current) return ["File is required"];
        return [];
    };

    return (
        <Modal
            onClose={() => props.onClose(false)}
            size={ModalSize.Medium}
            title={resource("UploadEventImage")}
        >
            <Input
                id="name"
                label={resource("Name")}
                validator={validator}
                validations={{ required: true }}
                value={name}
                onChange={e => setName(e.target.value)}
            />

            <Input
                id="file"
                type="file"
                validator={validator}
                validations={{ custom: () => validateFile() }}
                multiple={false}
                onChange={handleFileChanged}
            />

            <Button onClick={() => handleUploadClicked()}>{resource("Upload")}</Button>
        </Modal>
    );
};