import { FC } from "react";
import { Routes, Route } from "react-router-dom";
import { Languages } from "./Languages";
import { Resources } from "./Resources/Resources";

export const LanguagesArea: FC = () => {
    return (
        <Routes>
            <Route index element={<Languages />} />
            <Route path=":languageId/resources" element={<Resources />} />
        </Routes>
    )
};