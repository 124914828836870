export enum HttpStatusCode {
    Http100Continue = 100,
    Http101SwitchingProtocols = 101,
    Http102Processing = 102,
    Http103EarlyHints = 103,
    Http200Ok = 200,
    Http201Created = 201,
    Http202Accepted = 202,
    Http203NonAuthoritativeInformation = 203,
    Http204NoContent = 204,
    Http205ResetContent = 205,
    Http206PartialContent = 206,
    Http207MultiStatus = 207,
    Http208AlreadyReported = 208,
    Http226ImUsed = 226,
    Http300MultipleChoices = 300,
    Http301MovedPermanently = 301,
    Http302Found = 302,
    Http303SeeOther = 303,
    Http304NotModified = 304,
    Http305UseProxy = 305,
    Http306Unused = 306,
    Http307TemporaryRedirect = 307,
    Http308PermanentRedirect = 308,
    Http400BadRequest = 400,
    Http401Unauthorized = 401,
    Http402PaymentRequired = 402,
    Http403Forbidden = 403,
    Http404NotFound = 404,
    Http405MethodNotAllowed = 405,
    Http406NotAcceptable = 406,
    Http407ProxyAuthenticationRequired = 407,
    Http408RequestTimeout = 408,
    Http409Conflict = 409,
    Http410Gone = 410,
    Http411LengthRequired = 411,
    Http412PreconditionFailed = 412,
    Http413PayloadTooLarge = 413,
    Http414UriTooLong = 414,
    Http415UnsupportedMediaType = 415,
    Http416RangeNotSatisfiable = 416,
    Http417ExpectationFailed = 417,
    Http418ImATeapot = 418,
    Http421MisdirectedRequest = 421,
    Http422UnprocessableEntity = 422,
    Http423Locked = 423,
    Http424FailedDependency = 424,
    Http425TooEarly = 425,
    Http426UpgradeRequired = 426,
    Http428PreconditionRequired = 428,
    Http429TooManyRequests = 429,
    Http431RequestHeaderFieldsTooLarge = 431,
    Http451UnavailableForLegalReasons = 451,
    Http500InternalServerError = 500,
    Http501NotImplemented = 501,
    Http502BadGateway = 502,
    Http503ServiceUnavailable = 503,
    Http504GatewayTimeout = 504,
    Http505HttpVersionNotSupported = 505,
    Http506VariantAlsoNegotiates = 506,
    Http507InsufficientStorage = 507,
    Http508LoopDetected = 508,
    Http510NotExtended = 510,
    Http511NetworkAuthenticationRequired = 511
}