import * as React from "react";
import styles from "./Card.module.scss";
import { FC, PropsWithChildren } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { classNames } from "../../Utilities";

interface CardProps {
    onClick?: () => void;
    cover?: React.ReactNode;
    title?: React.ReactNode;
    titleBold?: boolean;
    footer?: React.ReactNode;
    className?: string;
    coverClassName?: string;
    titleClassName?: string;
    bodyClassName?: string;
    footerClassName?: string;
    shadow?: boolean;
    onClose?: () => void;
}

export const Card: FC<PropsWithChildren<CardProps>> = (props) => {
    const handleCloseClicked = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        e.stopPropagation();
        props.onClose?.();
    };

    return (
        <div
            className={classNames([styles.card, props.shadow && styles.shadow, !!props.onClick && styles.clickable, props.className])}
            onClick={props.onClick}
        >
            {!!props.cover && (
                <div className={classNames([styles.cover, props.coverClassName])}>
                    {props.cover}
                </div>
            )}

            {!!props.title && (
                <div className={classNames([styles.title, props.titleClassName])}>
                    <span className={classNames([styles.text, props.titleBold && styles.bold])}>
                        {props.title}
                    </span>
                    {!!props.onClose && (
                        <span className={styles.close} onClick={(e) => handleCloseClicked(e)}>
                            <FontAwesomeIcon icon={faXmark} />
                        </span>
                    )}
                </div>
            )}

            {!!props.children && (
                <div className={classNames([styles.body, props.bodyClassName])}>
                    {props.children}
                </div>
            )}

            {!!props.footer && (
                <div className={classNames([styles.footer, props.footerClassName])}>
                    {props.footer}
                </div>
            )}
        </div>
    );
};