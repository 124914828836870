import { Button, Form, Input, Modal } from "aphrodite.react/Components";
import { FC, useCallback, useEffect, useState } from "react";
import { useApp } from "Context/AppContext";
import { useValidator } from "aphrodite.react/Hooks";
import { StaffClient } from "AdminApi/AdminApi";
import { HttpStatusCode } from "aphrodite.react/Helpers";
import { ModalSize } from "aphrodite.react/Components/Modal/Modal";

interface StaffMemberEditModalProps {
    onClose: (dirty: boolean) => void;
    staffMemberId?: string;
}

export const StaffMemberEditModal: FC<StaffMemberEditModalProps> = (props) => {
    const { resource } = useApp();
    const validator = useValidator();

    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    const getStaffMember = async () => {
        const response = await new StaffClient().get(props.staffMemberId!);

        if (response.status === HttpStatusCode.Http200Ok) {
            setName(response.result.name);
            setEmail(response.result.email);
        }
    };

    const handleSaveClicked = async () => {
        if (!validator.canSubmit()) return;

        if (!props.staffMemberId) {
            // Create
            const response = await new StaffClient().post({
                name,
                email,
                password
            });

            if (response.status === HttpStatusCode.Http201Created) {
                props.onClose(true);
            }
        } else {
            // Update
            const response = await new StaffClient().put(props.staffMemberId, {
                name,
                email
            });

            if (response.status === HttpStatusCode.Http204NoContent) {
                props.onClose(true);
            }
        }
    };

    useEffect(() => {
        if (!!props.staffMemberId) {
            getStaffMember();
        }
    }, [props.staffMemberId]);

    return (
        <Modal
            onClose={() => props.onClose(false)}
            size={ModalSize.Medium}
        >
            <Form>
                <Input
                    id="name"
                    label={resource("Name")}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    validator={validator}
                    validations={{ required: true }}
                />

                <Input
                    id="email"
                    label={resource("Email")}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    validator={validator}
                    validations={{ required: true }}
                />

                {!props.staffMemberId && (
                    <Input
                        id="password"
                        label={resource("Password")}
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        validator={validator}
                        validations={{ required: true }}
                    />
                )}

                <div>
                    <Button onClick={() => handleSaveClicked()}>{resource("Save")}</Button>
                </div>
            </Form>
        </Modal>
    );
}