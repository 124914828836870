import { FC, useEffect, useState } from "react";
import { useApp } from "Context/AppContext";
import { LanguageClient, LanguageGetResponse } from "AdminApi/AdminApi";
import { HttpStatusCode, Severity } from "aphrodite.react/Helpers";
import { AutoTable, Button, Header } from "aphrodite.react/Components";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTable, faWarning } from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";

export const Languages: FC = () => {
    const { resource } = useApp();
    const navigate = useNavigate();

    const [languages, setLanguages] = useState<LanguageGetResponse[] | null>(null);

    const refreshLanguages = async () => {
        const response = await new LanguageClient().getAll();

        if (response.status === HttpStatusCode.Http200Ok) {
            setLanguages(response.result);
        };
    };

    useEffect(() => {
        refreshLanguages();
    }, []);

    return (
        <>
            <Header
                title={resource("Languages")}
            />

            <AutoTable
                items={languages}
                rowKey={l => l.id.toString()}
                columns={[
                    { header: resource("LanguageEnglishName"), display: l => l.englishName },
                    { header: resource("LanguageLocalisedName"), display: l => l.localisedName },
                    { header: resource("LanguageCode"), display: l => l.languageCode },
                    { header: resource("LanguageRegionCode"), display: l => l.regionCode },
                    {
                        header: resource("Status"),
                        display: l => (
                            <>
                                {!l.missingTranslations && <FontAwesomeIcon icon={faCheckCircle} />}
                                {!!l.missingTranslations && <FontAwesomeIcon icon={faWarning} />}
                                {" "}
                                <span>{resource("MissingTranslations", { 1: l.missingTranslations })}</span>
                            </>
                        )
                    },
                    {
                        display: l => (
                            <>
                                <Button
                                    onClick={() => navigate(`${l.id}/resources`)}
                                    title={resource("Resources")}
                                    severity={Severity.Warning}
                                >
                                    <FontAwesomeIcon icon={faTable} />
                                </Button>
                            </>
                        )
                    }
                ]}
            />
        </>
    );
};