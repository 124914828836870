import * as React from "react";
import { InputCommonProps, ValidatedInputProps } from "./Common";
import { useForceUpdate } from "../../Hooks";
import commonStyles from "./Common.module.scss";
import { classNames } from "../../Utilities";

interface InputProps {
    autoFocus?: boolean;
}

export const Input: React.FC<InputProps & ValidatedInputProps & InputCommonProps & React.InputHTMLAttributes<HTMLInputElement>> = (props) => {
    const {
        validator,
        validations,
        customMessages,
        label,
        className,
        autoFocus,
        ...inputProps
    } = props;

    const forceUpdate = useForceUpdate();
    const inputRef = React.useRef<HTMLInputElement>(null);


    React.useEffect(() => {
        validator?.register({
            key: props.id,
            validations: validations,
            value: props.value?.toString(),
            forceUpdate: forceUpdate,
            validationMessages: customMessages
        });

        if (autoFocus) inputRef?.current?.focus();

        return () => {
            validator?.unregister(props.id);
        };
    }, []);

    React.useEffect(() => {
        if (!!validator) {
            validator.setValue(props.id, props.value?.toString() ?? null);
            forceUpdate();
        }
    }, [props.value]);

    const isErrorState = (): boolean => {
        return !!validator && validator.submitAttempted() && validator.getErrors(props.id).length > 0;
    };

    return (
        <div className={commonStyles.container}>
            {!!label && (
                <div className={classNames([commonStyles.label, isErrorState() && commonStyles.error])}>
                    <label data-required={props.validations?.required} htmlFor={props.id}>{label}</label>
                </div>
            )}

            <div className={commonStyles.input}>
                <input
                    ref={inputRef}
                    className={classNames([className, isErrorState() && commonStyles.error])}
                    {...inputProps}
                />
            </div>

            {isErrorState() && (
                <div className={commonStyles.validation}>
                    {validator?.getErrors(props.id).map((message) => {
                        return <div key={message}>{message}</div>;
                    })}
                </div>
            )}

        </div>
    );
};