export class ApiBase {
    protected transformOptions = async (options: RequestInit): Promise<RequestInit> => {
        options.credentials = "include";
        return Promise.resolve(options);
    }

    protected getBaseUrl = (_: string, _baseUrl?: string): string => {
        return "";
    }

    protected transformResult(_url: string, response: Response, processor: (response: Response) => any) {
        return processor(response);
    }
}