import { AutoTable, Header } from "aphrodite.react/Components";
import { FC, useEffect, useState } from "react";
import { useApp } from "Context/AppContext";
import { WorkflowClient, WorkflowGetAllResponse } from "AdminApi/AdminApi";
import { HttpStatusCode } from "aphrodite.react/Helpers";

export const Workflows: FC = () => {
    const { resource } = useApp();

    const [workflows, setWorkflows] = useState<WorkflowGetAllResponse[] | null>(null);

    const refreshWorkflows = async () => {
        const response = await new WorkflowClient().getAll();

        if (response.status === HttpStatusCode.Http200Ok) {
            setWorkflows(response.result);
        }
    };

    useEffect(() => {
        refreshWorkflows();
    }, []);

    return (
        <>
            <Header
                title={resource("Workflows")}
            />

            <AutoTable
                items={workflows}
                rowKey={w => w.workflowId.toString()}
                columns={[
                    { header: resource("Name"), display: w => w.name }
                ]}
            />
        </>
    );
};