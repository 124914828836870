import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EventClient, EventCustomerSignUpClient, EventCustomerSignUpGetAllResponse, EventGetResponse } from "AdminApi/AdminApi";
import { DateFormatter, HttpStatusCode } from "aphrodite.react/Helpers";
import { AutoTable, Card, Header, NoInput } from "aphrodite.react/Components";
import { useEvents } from "./EventsContext";
import { useApp } from "Context/AppContext";
import { useAdmin } from "../AdminContext";

type EventParams = {
    eventId: string;
}

export const Event: FC = () => {
    const params = useParams<EventParams>();
    const { resource } = useApp();
    const { countries } = useAdmin();
    const { workflows } = useEvents();

    const [event, setEvent] = useState<EventGetResponse | null>(null);
    const [signUps, setSignUps] = useState<EventCustomerSignUpGetAllResponse[] | null>(null);

    const refreshEvent = async () => {
        const response = await new EventClient().get(params.eventId!);

        if (response.status === HttpStatusCode.Http200Ok) {
            setEvent(response.result);
        }
    };

    const refreshSignUps = async () => {
        const response = await new EventCustomerSignUpClient().getAll(params.eventId!);

        if (response.status === HttpStatusCode.Http200Ok) {
            setSignUps(response.result);
        }
    }

    useEffect(() => {
        refreshEvent();
        refreshSignUps();
    }, []);

    if (!event) return null;

    return (
        <div>
            <Header
                title={event.name}
            />

            <Card>
                <NoInput
                    label={resource("Starting")}
                    value={`${DateFormatter.localeDateLocal(event.start)} ${DateFormatter.localeTimeLocal(event.start)}`}
                />

            {/*    <NoInput*/}
            {/*        label={resource("Workflow")}*/}
            {/*        value={workflows?.find(w => w.workflowId === event.workflowId)?.name ?? resource("NoneValue")}*/}
            {/*    />*/}

            {/*    <NoInput*/}
            {/*        label={resource("Country")}*/}
            {/*        value={countries?.find(c => c.id === event.countryId)?.name ?? resource("NoneValue")}*/}
            {/*    />*/}
            </Card>

            <Header
                title={resource("Customers")}
            />

            <Card>
                <AutoTable
                    items={signUps}
                    rowKey={s => s.id.toString()}
                    columns={[
                        { header: resource("Email"), display: s => s.email },
                        { header: resource("FirstName"), display: s => s.firstName },
                        { header: resource("LastName"), display: s => s.lastName }
                    ]}
                />
            </Card>
        </div>
    );
};