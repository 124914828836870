import { FC, useEffect, useState } from "react";
import { AdminPermission, StaffClient, StaffGetAllResponse } from "AdminApi/AdminApi";
import { HttpStatusCode, Severity } from "aphrodite.react/Helpers";
import { AutoTable, Button, Header } from "aphrodite.react/Components";
import { useApp } from "Context/AppContext";
import { Link } from "react-router-dom";
import { StaffMemberEditModal } from "Modals/StaffEditModal";
import { HasPermission } from "Components/HasPermission";

export const Staff: FC = () => {
    const { resource } = useApp();

    const [staff, setStaff] = useState<StaffGetAllResponse[] | null>(null);
    const [create, setCreate] = useState(false);

    const refreshStaff = async () => {
        const response = await new StaffClient().getAll();

        if (response.status === HttpStatusCode.Http200Ok) {
            setStaff(response.result);
        }
    };

    const handleModalClosed = (dirty: boolean) => {
        setCreate(false);
        if (dirty) {
            refreshStaff();
        }
    };

    useEffect(() => {
        refreshStaff();
    }, []);

    return (
        <>
            <Header
                title={resource("Staff")}
                right={
                    <span>
                        <HasPermission permission={AdminPermission.StaffCreate}>
                            <Button severity={Severity.Success} onClick={() => setCreate(true)}>{resource("New")}</Button>
                        </HasPermission>
                    </span>
                }
            />

            <AutoTable
                items={staff}
                rowKey={s => s.id}
                columns={[
                    {
                        header: resource("Email"),
                        display: s => (
                            <Link to={s.id}>{s.email}</Link>
                        )
                    },
                    { header: resource("Name"), display: s => s.name }
                ]}
            />

            {create && (
                <StaffMemberEditModal
                    onClose={handleModalClosed}
                />
            )}
        </>
    );
};