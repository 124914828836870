import { createContext, useContext } from "react";
import { CountryGetAllResponse } from "AdminApi/AdminApi";

interface AdminContextState {
    countries: CountryGetAllResponse[] | null;
    refreshCountries: () => void;
}

export const AdminContext = createContext<AdminContextState>({
    countries: null,
    refreshCountries: () => { }
});

export function useAdmin() {
    return useContext(AdminContext);
}