import { classNames } from "../../Utilities";
import * as React from "react";
import styles from "./Modal.module.scss";
import { Card } from "../Card";

const escapeKey: number = 27;
const stack: string[] = [];

interface ModalProps {
    className?: string;
    size?: ModalSize;
    title?: string;
    onClose: (dirty: boolean) => void;
}

export enum ModalSize {
    Small,
    Medium,
    Large,
    FullWidth,
    FullScreen,
    Auto
}

export const Modal: React.FC<React.PropsWithChildren<ModalProps>> = (props) => {
    const id = React.useMemo(() => Math.random().toString(36).substring(2), []);

    const modalSize = props.size ?? ModalSize.FullWidth;

    const getSizeClass = () => {
        switch (modalSize) {
            case ModalSize.Small:
                return styles.small;
            case ModalSize.Medium:
                return styles.medium;
            case ModalSize.Large:
                return styles.large;
            case ModalSize.FullWidth:
                return styles.fullWidth;
            case ModalSize.FullScreen:
                return styles.fullScreen;
            case ModalSize.Auto:
                return styles.auto;
        }
    };

    React.useEffect(() => {
        document.addEventListener("keydown", (e) => onKeyDown(e.keyCode), false);
        stack.push(id);

        return () => {
            document.removeEventListener("keydown", (e) => onKeyDown(e.keyCode), false);
            stack.pop();
        };
    }, [id]);

    const onKeyDown = (keyCode: number) => {
        if (keyCode === escapeKey && stack[stack.length - 1] === id) {
            props.onClose(false);
        }
    };

    return (
        <div className={classNames([styles.modal])}>
            <Card
                className={classNames([styles.modalContent, getSizeClass(), props.className])}
                title={props.title}
                titleBold={true}
                onClose={() => props.onClose(false)}
            >
                {props.children}
            </Card>
        </div>
    );
};