// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SBV2PblZXa7An49850nw{border-radius:8px;display:flex;flex-direction:column;align-items:center;background-color:#fff;padding:8px;cursor:pointer}.SBV2PblZXa7An49850nw .vp3PwODdYJsQdxrGvmBa{margin-top:4px;font-size:20px}`, "",{"version":3,"sources":["webpack://./Client/Components/Tile/Tile.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,qBAAA,CACA,WAAA,CACA,cAAA,CAEA,4CACE,cAAA,CACA,cAAA","sourcesContent":[".tile{border-radius:8px;display:flex;flex-direction:column;align-items:center;background-color:#fff;padding:8px;cursor:pointer}.tile .title{margin-top:4px;font-size:20px}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tile": `SBV2PblZXa7An49850nw`,
	"title": `vp3PwODdYJsQdxrGvmBa`
};
export default ___CSS_LOADER_EXPORT___;
