import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { Workflows } from "./Workflows";

export const WorkflowsArea: FC = () => {
    return (
        <Routes>
            <Route index element={<Workflows />} />
        </Routes>
    );
};