// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Yhm6AAjxuDEJdB8Hidzk{display:flex;flex-wrap:wrap;gap:8px}.Yhm6AAjxuDEJdB8Hidzk>div{width:300px}`, "",{"version":3,"sources":["webpack://./Client/Pages/Admin/Admin.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,cAAA,CACA,OAAA,CAEA,0BACE,WAAA","sourcesContent":[".tiles{display:flex;flex-wrap:wrap;gap:8px}.tiles>div{width:300px}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tiles": `Yhm6AAjxuDEJdB8Hidzk`
};
export default ___CSS_LOADER_EXPORT___;
