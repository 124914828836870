import { AutoTableColumn } from "./AutoTableColumn";
import styles from "./AutoTable.module.scss";

interface AutoTableProps<T> {
    items: T[] | null;
    columns: AutoTableColumn<T>[];
    rowKey: (item: T) => string;
    className?: string;
    noDataText?: string;
}

export const AutoTable = <T,>(props: AutoTableProps<T>) => {
    if (!props.items) return null;

    const columns = props.columns.filter(column => !column.hidden);

    return (
        <table className={props.className ?? ""}>
            <thead>
                <tr>
                    {columns.map((column, index) => (
                        <th key={index} className={column.className ?? ""}>{column.header}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {!props.items?.length && (
                    <tr>
                        <td colSpan={columns.length} className={styles.noData}>
                            {props.noDataText ?? "No data"}
                        </td>
                    </tr>
                )}
                {props.items.map((item) => (
                    <tr key={props.rowKey(item)}>
                        {columns.map((column, index) => (
                            <td key={index} className={column.className ?? ""}>{column.display(item)}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};