// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mFWli7erOTjrzpGWvxw1 .w9WyLebfj7K_680k9cQE img{max-height:100px;max-width:100px}`, "",{"version":3,"sources":["webpack://./Client/Pages/Admin/EventImages/EventImages.module.scss"],"names":[],"mappings":"AAEI,gDACE,gBAAA,CACA,eAAA","sourcesContent":[".table .image img{max-height:100px;max-width:100px}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `mFWli7erOTjrzpGWvxw1`,
	"image": `w9WyLebfj7K_680k9cQE`
};
export default ___CSS_LOADER_EXPORT___;
